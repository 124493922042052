import React, { Component } from "react";
import TermsContent from "./Terms.md";
import MainContent from "../MainContent";
import marked from "marked";
import DocumentTitle from "react-document-title";
import styled from "styled-components";

const Container = styled(MainContent)`
  margin-top: 40px;
`;

class Terms extends Component {
  state = {};
  componentDidUpdate() {
    window.scrollTo(0, 0);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    fetch(TermsContent)
      .then(response => response.text())
      .then(text => {
        this.setState({ content: marked(text, { breaks: true }) });
      });
  }
  render() {
    return (
      <DocumentTitle title={`Wego | Terms`}>
        <div>
          <Container>
            <div dangerouslySetInnerHTML={{ __html: this.state.content }} />
          </Container>
        </div>
      </DocumentTitle>
    );
  }
}

export default Terms;

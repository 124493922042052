import React, { Component } from "react";
import styled from "styled-components";
import OuterLayer from "../OuterLayer";
import downtownPlane from "../../assets/images/smit-patel-363888.jpg";
import Link from "../Link";
import { routes } from "../../constants";
import moneyIcon from "../../assets/images/shop-with-confidence.svg";
import searchIcon from "../../assets/images/look-no-further.svg";
import DocumentTitle from "react-document-title";

const Banner = styled.div`
  background: #000;
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(40, 40, 40, 0.6)), url(${downtownPlane}) no-repeat 0% 70%;
  background-size: cover;
  height: 320px;
`;

const BannerDescription = styled.div`
  text-align: center;
  padding: 96px 0;

  color: #fff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.8);
  margin: 0 auto;
  z-index: 100;
  position: relative;
  text-rendering: optimizeLegibility;
  max-width: 760px;

  h1,
  h4 {
    border: none;
  }
`;

const CardContainer = styled(OuterLayer)`
  margin-top: 56px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 420px) {
    flex-direction: column;
    align-items: center;
  }
`;

const CardLink = styled(Link)`
  max-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: #333;
  padding: 16px 16px;

  margin: 8px;
  text-align: center;
  border: 1px solid #fff;
  border: 1px solid #eee;

  img {
    padding: 16px;
  }
  p {
    text-decoration: none;
    font-size: 16px;
    line-height: 1.38;
    color: #9b9b9b;
    paddig: 16px;
    width: 70%;
  }

  .title {
    text-decoration: underline;
    marging: 0;
    padding: 0;
  }
`;

class Home extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  componentDidUpdate() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <DocumentTitle title={`Wego | Developers Portal`}>
        <div>
          <OuterLayer>
            <Banner>
              <BannerDescription>
                <h1>
                  <strong>Wego Developers Portal</strong>
                </h1>
              </BannerDescription>
            </Banner>
          </OuterLayer>
          <CardContainer>
            <CardLink to={routes.affiliates.home} highlightDisabled={true}>
              <img src={moneyIcon} alt="money-icon" />
              <div class="title">Affiliates</div>
              <p>Power your travel website with Wego APIs</p>
            </CardLink>
            <CardLink to={routes.providers} highlightDisabled={true}>
              <img src={searchIcon} alt="search-icon" />
              <div class="title">Providers</div>
              <p>Display your Flight airfares and Hotel rates on Wego</p>
            </CardLink>
          </CardContainer>
        </div>
      </DocumentTitle>
    );
  }
}

export default Home;

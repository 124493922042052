import React, { Component } from "react";
import MainContent from "../MainContent";
import styled from "styled-components";
import { Route, Switch, Redirect } from "react-router-dom";
import AuthenticationGuide from "./AuthenticationGuide";
import HotelsGuide from "./HotelsGuide";
import FlightsGuide from "./FlightsGuide";
import Sidebar from "../Sidebar";
import Link from "../Link";
import { routes } from "../../constants";
import DocumentTitle from "react-document-title";
import { Mobile, Desktop } from "../responsive";
import SidebarToggle from "../SidebarToggle";
import FacilitatedBookingGuide from "./FacilitatedBookingGuide";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const TableOfContents = () => (
  <React.Fragment>
    <Link to={routes.affiliates.authentication}>Authentication</Link>
    <Link to={`${routes.affiliates.guides}/flights`}>Flights</Link>
    <Link to={`${routes.affiliates.guides}/hotels`}>Hotels</Link>
  </React.Fragment>
);

class Guides extends Component {
  constructor(props) {
    super(props);
    this.state = { sideBarOpen: false };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  toggleSidebar(value) {
    const newValue = value || !this.state.sideBarOpen;
    this.setState({ sideBarOpen: newValue });
  }
  render() {
    return (
      <DocumentTitle title={`Wego | Affiliates`}>
        <Wrapper>
          <Mobile>
            <Sidebar onClick={() => this.toggleSidebar(false)} mobile sideBarOpen={this.state.sideBarOpen}>
              <TableOfContents />
            </Sidebar>
          </Mobile>
          <Desktop>
            <Sidebar>
              <TableOfContents />
            </Sidebar>
          </Desktop>
          <SidebarToggle onClick={() => this.toggleSidebar()} />

          <MainContent>
            <Switch>
              <Route exact path={`${routes.affiliates.guides}/flights`} component={FlightsGuide} />
              <Route exact path={`${routes.affiliates.facilitatedBooking}`} component={FacilitatedBookingGuide} />
              <Route path={`${routes.affiliates.guides}/hotels`} component={HotelsGuide} />
              <Route path={routes.affiliates.authentication} component={AuthenticationGuide} />
              <Route render={() => <Redirect to={routes.affiliates.authentication} />} />
            </Switch>
          </MainContent>
        </Wrapper>
      </DocumentTitle>
    );
  }
}

export default Guides;

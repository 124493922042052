import React, { Component } from "react";
import Header from "./components/Header";
import styled from "styled-components";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import NavLink from "./components/Header/NavLink";
import Overview from "./components/Overview";
import Guides from "./components/Guides";
import OuterLayer from "./components/OuterLayer";
import ApiReference from "./components/ApiReference";
import githugLobo from "./assets/images/github_logo.png";
import Terms from "./components/Terms";
import Providers from "./components/Providers";
import { routes } from "./constants";
import Home from "./components/Home";

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 1024px;
  > :first-child {
    width: 100%;
    padding: 40px 16px;
  }
  @media (max-width: 767px) {
    > :first-child {
      width: 100%;
    }
  }
`;

const FooterIcon = styled.img`
  width: 18px;
  height: 18px;
  padding: 2px 4px 0 4px;
`;

const FooterContainer = styled(OuterLayer)`
  color: #9b9b9b;
  font-weight: 400;
  height: 64px;
  margin: 0;
  a {
    color: #9b9b9b;
    font-weight: 400;
    text-decoration: underline;
  }
`;

const Footer = styled.footer`
  display: flex;
  > :first-child {
    flex: 1;
  }
`;

const FooterContent = () => {
  const year = new Date().getFullYear();
  return (
    <Footer>
      <FooterContainer>
        <Wrapper>
          <Flex>
            ©{year} Wego Pte Ltd. All rights reserved.
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/wego"
            >
              <FooterIcon src={githugLobo} />
            </a>
          </Flex>
        </Wrapper>
      </FooterContainer>
    </Footer>
  );
};

const Container = styled.div`
  margin-top: 40px;
  @media (max-width: 420px) {
    margin-top: 48px;
  }
`;

const NavMenu = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 420px) {
    justify-content: space-between;
    a {
      flex-grow: 1;
      text-align: center;
      display: flex;
      justify-content: center;
    }
  }
`;

const HeaderNavigation = () => (
  <Switch>
    <Route
      path="/affiliates"
      render={() => (
        <Header>
          <NavMenu>
            <NavLink to={routes.affiliates.getStarted}>Get Started</NavLink>
            <NavLink to={routes.affiliates.guides}>Guides</NavLink>
            <NavLink to={routes.affiliates.terms}>Terms</NavLink>
            <NavLink to={routes.affiliates.reference}>References</NavLink>
          </NavMenu>
        </Header>
      )}
    />
    <Route render={() => <Header />} />
  </Switch>
);

const Affiliates = () => {
  return (
    <div>
      <Switch>
        <Route path={routes.affiliates.getStarted} component={Overview} />
        <Route path={routes.affiliates.guides} component={Guides} />
        <Route path={routes.affiliates.terms} component={Terms} />
        <Route path={routes.affiliates.reference} component={ApiReference} />
        <Route
          path={routes.affiliates.home}
          render={() => <Redirect to={routes.affiliates.getStarted} />}
        />
      </Switch>
    </div>
  );
};

class App extends Component {
  render() {
    return (
      <Router onUpdate={() => window.scrollTo(0, 0)}>
        <div>
          <HeaderNavigation />
          <Route exact path="/" render={() => <Home />} />
          <Container>
            <OuterLayer>
              <Wrapper>
                <Route path={routes.affiliates.home} component={Affiliates} />
                <Switch>
                  <Route
                    path={`${routes.providers}/:content`}
                    component={Providers}
                  />
                  <Route
                    path={routes.providers}
                    render={() => (
                      <Redirect to={`${routes.providers}/welcome`} />
                    )}
                  />
                </Switch>
              </Wrapper>
            </OuterLayer>
          </Container>
          <FooterContent />
        </div>
      </Router>
    );
  }
}

export default App;

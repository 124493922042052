import styled from "styled-components";

export default styled.div`
  max-width: 712px;
  width: 100%;
  margin-left: auto;
  flex: 1;
  @media (max-width: 767px) {
    max-width: 90vw;
    margin-top: 20px;
  }
`;

import styled from "styled-components";

export default styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  > * {
    width: 100%;
    padding: 0 16px;
    @media (max-width: 420px) {
      padding: 0;
    }
  }
`;

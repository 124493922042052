import React from "react";
import styled from "styled-components";

const NoteWrapper = styled.div`
  padding: 16px;
  color: rgb(82, 82, 82);
  border: 1px solid #dedede;
  border-left: 6px solid #c6c5c7;
  box-shadow: 0 0 5px rgba(112, 109, 120, 0.3);
  &.${props => props.high} {
    border-left: 6px solid #7bb242;
    color: red;
  }
`;

export default props => <NoteWrapper {...props}>{props.children}</NoteWrapper>;

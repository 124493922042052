// @ts-check
import React, { Component } from "react";
import styled from "styled-components";
import prettify from "code-prettify";

const Wrapper = styled.div`
  border-radius: 0.25em;
  color: #6d6d6d;
  border: 1px #e5e5e5 solid;
  font-size: 0.95em;
  line-height: 1.4;
  margin: 0 0 0.7rem 0;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  padding: 16px;
  font-family: "Open Sans", sans-serif;
`;

const SnippetHeader = styled.div`
  display: flex;
`;

const Label = styled.div`
  font-weight: bold;
  flex: 1;
`;

// const Highlight = styled.div`
//   color: #706d78;
//   ${Wrapper}:hover & {
//     color: #dedede;
//     transition: color 0.3s ease;
//   }
// `;

const Expander = styled.div`
  position: absolute;
  right: 45%;
  cursor: pointer;
  bottom: 48px;
  padding: 4px 6px;
  font-size: 0.8em;
  background-color: #b5b7b7;
  border-radius: 16px;
  color: #525353;
`;

const blurryBottom = `::after {
  padding: 8px;
  content: "";
  position: absolute;
  right: 16px;
  bottom: 30px;
  left: 16px;
  height: 40px;
  border-radius: 0.25em;
  background-image: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0,
    #2c2d2c 100%
  );
  background-image: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0,
    #2c2d2c 100%
  );
  background-image: -o-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0,
    #2c2d2c 100%
  );
  background-image: linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0,
    #2c2d2c 100%
  );
}`;

const Pre = styled.pre`
  overflow: auto;
  border-radius: 0.25em;
  padding: 8px;
  overflow-y: hidden;
  font-size: 0.8em;
  ${props => !props.collapsed && blurryBottom};
  ${props => !props.collapsed && "height: auto"};
  ${props => props.adjustHeight && "height: 300px"};
`;

class CodeSnippet extends Component {
  constructor(props) {
    super(props);
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.state = {
      collapsed: false,
      adjustHeight: false,
      showExpander: true
    };
  }
  toggleCollapse() {
    const nextCollapsedState = !this.state.collapsed;

    if (!nextCollapsedState) {
      this.wrapperElement.scrollIntoView({ block: "end", behavior: "smooth" });
    }
    window.setTimeout(
      () =>
        this.setState({
          collapsed: nextCollapsedState,
          adjustHeight: !this.state.adjustHeight
        }),
      1
    );
  }
  componentDidMount() {
    if (this.props.children) {
      if (this.codeElement.clientHeight <= 320) {
        this.setState({ showExpander: false, collapsed: true });
      } else {
        this.setState({ adjustHeight: true });
      }
    }
  }
  render() {
    const { children, label } = this.props;
    const { adjustHeight, collapsed, showExpander } = this.state;
    const codeData = prettify.prettyPrintOne(children);
    // let copyText = "Click to copy";
    return (
      <Wrapper
        innerRef={wrapperElement => (this.wrapperElement = wrapperElement)}
      >
        <SnippetHeader>
          <Label>{label}</Label>
          {/* <Highlight>Click to copy</Highlight> */}
        </SnippetHeader>

        {children && (
          <div>
            <div ref={codeElement => (this.codeElement = codeElement)}>
              <Pre
                adjustHeight={adjustHeight}
                className="prettyprint linenums"
                collapsed={collapsed}
              >
                <div dangerouslySetInnerHTML={{ __html: codeData }} />
              </Pre>
            </div>
            {showExpander && (
              <Expander onClick={this.toggleCollapse}>
                {collapsed ? "^ Collapse" : "+ Click to expand"}
              </Expander>
            )}
          </div>
        )}
      </Wrapper>
    );
  }
}

export default CodeSnippet;

// @ts-check
import React, { Component } from "react";
import MainContent from "../MainContent";
import styled from "styled-components";
import { Switch, Route } from "react-router-dom";
import DocumentTitle from "react-document-title";
import flightsReferenceMd from "./FlightsReference.md";
import hotelsReferenceMd from "./HotelsReference.md";
// import FlightsReference from "./FlightsReference";
import marked from "marked";
import Sidebar from "../Sidebar";
import Link from "../Link";
import { HashLink } from "react-router-hash-link";
import authReferenceMd from "./AuthenticationReference.md";
import generalObjectsMd from "./GeneralObjects.md";
import flightObjectsMd from "./FlightObjects.md";
import hotelObjectsMd from "./HotelObjects.md";
import errorObjectsMd from "./ErrorObjects.md";
import placesReferenceMd from "./PlacesReference.md";
import flightBookingReferenceMd from "./FlightBookingReference.md";
import { routes } from "../../constants";
import { Mobile, Desktop } from "../responsive";
import SidebarToggle from "../SidebarToggle";

const files = {
  authReferenceMd,
  flightsReferenceMd,
  hotelsReferenceMd,
  generalObjectsMd,
  flightObjectsMd,
  hotelObjectsMd,
  errorObjectsMd,
  placesReferenceMd,
  flightBookingReferenceMd
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  a {
    color: #4a4a4a;
    text-decoration: none;
    :hover {
      text-decoration: underline;
    }
  }
`;

const Container = styled(MainContent)`
  h2,
  h3,
  h4 {
    padding-top: 72px;
    margin-top: -72px;
  }
  table {
    border-collapse: collapse;
    font-size: 0.85em;
  }
  thead {
    background-color: #edefef;
    color: #6e6f6f;
    font-weight: 600;
  }
  th,
  td {
    border: 1px solid #b5b7b7;
    padding: 8px;
  }
  pre {
    font-size: 0.95em;
    border-radius: 0.25em;
    padding: 16px;
    overflow: auto;
    background-color: #1d1f21;
  }
  code {
    background-color: #1d1f21;
    padding: 4px;
    color: #b5bd68;
    overflow: auto;
    line-height: 22px;
    font-family: Menlo, "Bitstream Vera Sans Mono", "DejaVu Sans Mono", Monaco, Consolas, monospace;
    font-size: 0.8em;
  }
  em {
    font-size: 0.8em;
    font-style: italic;
  }
  p,
  li {
    > code {
      background-color: #f6f8fa;
      font-size: 85%;
      color: #24292e;
    }
  }
  @media (max-width: 420px) {
    h1,
    h2,
    h3,
    h4 {
      padding-top: 120px;
      margin-top: -104px;
    }
  }
`;

const SubLinks = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

const FlightsSubLinks = () => (
  <SubLinks>
    <HashLink to={`${routes.affiliates.reference}/flights#create-search`}>Create Search</HashLink>
    <HashLink to={`${routes.affiliates.reference}/flights#get-search-results`}>Get Search Results</HashLink>
    <HashLink to={`${routes.affiliates.reference}/flights#get-trip`}>Get Trip</HashLink>
  </SubLinks>
);

const HotelsSubLinks = () => (
  <SubLinks>
    <HashLink to={`${routes.affiliates.reference}/hotels#create-search`}>Create Search</HashLink>
    <HashLink to={`${routes.affiliates.reference}/hotels#get-search-results`}>Get Search Results</HashLink>
    <HashLink to={`${routes.affiliates.reference}/hotels#get-hotel-rates`}>Get Hotel Rates</HashLink>
    <HashLink to={`${routes.affiliates.reference}/hotels#get-hotel-details`}>Get Hotel Details</HashLink>
  </SubLinks>
);

const TableOfContent = () => (
  <React.Fragment>
    <h2>Endpoints</h2>
    <h4>
      <Link to={`${routes.affiliates.reference}/authentication`}>Authentication</Link>
    </h4>
    <h4>
      <Link to={`${routes.affiliates.reference}/places`}>Places</Link>
    </h4>
    <SubLinks>
      <HashLink to={`${routes.affiliates.reference}/places#search`}>Search</HashLink>
      <HashLink to={`${routes.affiliates.reference}/places#popular-cities-for-flights`}>
        Popular Cities for Flights
      </HashLink>
      <HashLink to={`${routes.affiliates.reference}/places#popular-cities-for-hotels`}>
        Popular Cities for Hotels
      </HashLink>
    </SubLinks>
    <h4>
      <Link to={`${routes.affiliates.reference}/flights`}>Flights</Link>
    </h4>
    <FlightsSubLinks />
    {/* <h4>
      <Link to={`${routes.affiliates.reference}/facilitated-booking`}>
        Facilitated Booking
      </Link>
    </h4>
    <FlightBookingSubLinks />*/}
    <h4>
      <Link to={`${routes.affiliates.reference}/hotels`}>Hotels</Link>
    </h4>
    <HotelsSubLinks />
    <br />
    <h2>Objects</h2>
    <h4>
      <Link to={`${routes.affiliates.reference}/objects/general`}>General</Link>
    </h4>
    <h4>
      <Link to={`${routes.affiliates.reference}/objects/flights`}>Flights</Link>
    </h4>
    <h4>
      <Link to={`${routes.affiliates.reference}/objects/hotels`}>Hotels</Link>
    </h4>
    <h4>
      <Link to={`${routes.affiliates.reference}/objects/errors`}>Errors</Link>
    </h4>
  </React.Fragment>
);

const ApiReferenceSidebar = props => {
  return (
    <React.Fragment>
      <Mobile>
        <Sidebar onClick={() => props.toggleSidebar(false)} mobile sideBarOpen={props.sideBarOpen}>
          <TableOfContent />
        </Sidebar>
      </Mobile>
      <Desktop>
        <Sidebar>
          <TableOfContent />
        </Sidebar>
      </Desktop>
    </React.Fragment>
  );
};

const routesComponents = [
  {
    exact: true,
    path: `${routes.affiliates.reference}`,
    content: "authReferenceMd"
  },
  {
    path: `${routes.affiliates.reference}/places`,
    content: "placesReferenceMd"
  },
  {
    path: `${routes.affiliates.reference}/flights`,
    content: "flightsReferenceMd"
  },
  // {
  //   path: `${routes.affiliates.reference}/facilitated-booking`,
  //   content: "flightBookingReferenceMd"
  // },
  {
    path: `${routes.affiliates.reference}/authentication`,
    content: "authReferenceMd"
  },
  {
    path: `${routes.affiliates.reference}/hotels`,
    content: "hotelsReferenceMd"
  },
  {
    path: `${routes.affiliates.reference}/objects/general`,
    content: "generalObjectsMd"
  },
  {
    path: `${routes.affiliates.reference}/objects/flights`,
    content: "flightObjectsMd"
  },
  {
    path: `${routes.affiliates.reference}/objects/hotels`,
    content: "hotelObjectsMd"
  },
  {
    path: `${routes.affiliates.reference}/objects/errors`,
    content: "errorObjectsMd"
  }
];

class ApiReference extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authReferenceMd: "",
      flightsReferenceMd: "",
      hotelsReferenceMd: "",
      generalObjectsMd: "",
      hotelObjectsMd: "",
      errorObjectsMd: "",
      placesReferenceMd: "",
      flightBookingReferenceMd: "",
      sideBarOpen: false
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    Object.keys(files).forEach(markdownFileName => {
      fetch(files[markdownFileName])
        .then(response => response.text())
        .then(text => {
          // Logs a string of Markdown content.
          const newState = {};
          newState[markdownFileName] = marked(text, { breaks: true });
          this.setState(newState);
        });
    });
  }

  toggleSidebar(value) {
    const newValue = value || !this.state.sideBarOpen;
    this.setState({ sideBarOpen: newValue });
  }

  render() {
    const keys = Object.keys(this.state || {});
    return (
      <DocumentTitle title={`Wego | Affiliates`}>
        <Wrapper>
          <ApiReferenceSidebar toggleSidebar={this.toggleSidebar.bind(this)} sideBarOpen={this.state.sideBarOpen} />
          <SidebarToggle onClick={() => this.toggleSidebar()} />
          <Container>
            <Switch>
              {routesComponents.map(routeComponent => (
                <Route
                  exact={routeComponent.exact}
                  path={routeComponent.path}
                  render={() => {
                    return (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.state[routeComponent.content]
                        }}
                      />
                    );
                  }}
                />
              ))}
            </Switch>
          </Container>
        </Wrapper>
      </DocumentTitle>
    );
  }
}

export default ApiReference;

import React from "react";
import { Mobile } from "./responsive";
import ArrowIcon from "./icons/ArrowIcon";
import styled from "styled-components";

const SidebarToggle = styled.a`
  margin: 0 0 0 8px;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-repeat: repeat-x;
  background-position: -1px -1px;
  background-size: 110% 110%;
  border: 1px solid rgba(27, 31, 35, 0.2);
  border-radius: 0.25em;
  -webkit-appearance: none;
  height: 24px;
  width: 24px;
  display: flex;
  border: 1px solid rgba(27, 31, 35, 0.2);
  background-color: #eff3f6;
  :hover,
  :active {
    background-color: #e6ebf1;
    background-image: linear-gradient(-180deg, #f0f3f6 0%, #e6ebf1 90%);
    background-position: -0.5em;
    border-color: rgba(27, 31, 35, 0.35);
  }
  svg {
    width: 100%;
    height: 100%;
  }
  transition: 0.2s ease;
  transform: ${props => (props.collapsed ? "rotate(180deg)" : "rotate(360deg)")};
`;

const ToggleContainer = styled.div`
  padding: 4px 0;
  background-color: #ffffff;
  position: fixed;
  z-index: 9999;
  width: 100vw;
  box-shadow: rgb(101, 119, 134) 0px 0px 4px;
  left: 0;
`;

const Wrapper = styled.div`
  padding-bottom: 20px;
`;

export default props => (
  <Mobile>
    <Wrapper>
      <ToggleContainer>
        <SidebarToggle collapsed onClick={() => props.onClick()}>
          <ArrowIcon />
        </SidebarToggle>
      </ToggleContainer>
    </Wrapper>
  </Mobile>
);

import React, { Component } from "react";
import marked from "marked";
import DocumentTitle from "react-document-title";
import styled from "styled-components";
import Sidebar from "../Sidebar";
import Link from "../Link";
import MainContent from "../MainContent";
import { routes } from "../../constants";
import { Mobile, Desktop } from "../responsive";
import SidebarToggle from "../SidebarToggle";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  pre {
    font-size: 0.95em;
    border-radius: 0.25em;
    padding: 16px;
    overflow: auto;
    background-color: #1d1f21;
  }
  code {
    background-color: #1d1f21;
    padding: 4px;
    color: #b5bd68;
    overflow: auto;
    line-height: 22px;
    font-family: Menlo, "Bitstream Vera Sans Mono", "DejaVu Sans Mono", Monaco, Consolas, monospace;
    font-size: 0.8em;
  }
  em {
    font-size: 0.8em;
    font-style: italic;
  }
  p,
  li {
    > code {
      background-color: #f6f8fa;
      font-size: 85%;
      color: #24292e;
    }
  }
  table {
    border-collapse: collapse;
    font-size: 0.85em;
  }
  thead {
    background-color: #edefef;
    color: #6e6f6f;
    font-weight: 600;
  }
  th,
  td {
    border: 1px solid #b5b7b7;
    padding: 8px;
  }
  @media (max-width: 420px) {
    margin-top: -40px;
  }
`;

const SubLinks = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

const TableOfContents = () => (
  <React.Fragment>
    <Link to={routes.providersHome}>Contacts</Link>
    <Link to={routes.conversionTracking}>Conversion tracking</Link>
    <Link highlightDisabled={true} to={routes.flightsRequirements}>
      Flights
    </Link>
    <SubLinks>
      <Link to={routes.flightsRequirements}>Integration requirements</Link>
    </SubLinks>
    <Link highlightDisabled={true} to={routes.hotelsRequirements}>
      Hotels
    </Link>
     <SubLinks>
      <Link to={routes.hotelsRequirements}>Integration requirements</Link>
    </SubLinks>
  </React.Fragment>
);

class Providers extends Component {
  constructor(props) {
    super(props);
    this.state = { sideBarOpen: false };
  }

  componentDidMount() {
    const params = this.props.match.params;
    const pathParam = (params && params.content) || routes.providersHome;
    this.fetchContent(pathParam);
    window.scrollTo(0, 0);
  }

  componentWillReceiveProps(nextProps) {
    const pathParam = nextProps.match.params.content || routes.providersHome;
    if (pathParam !== this.state.page) {
      this.fetchContent(pathParam);
    }
  }

  componentDidUpdate() {
    const hashLocation = this.props.location.hash;
    const content = this.state.content;
    if (hashLocation && content) {
      // hack
      const $element = document.getElementById(hashLocation.replace("#", ""));
      setTimeout(() => {
        $element.scrollIntoView(true);
        window.scrollBy(0, -70);
      }, 100);
    } else {
      window.scrollTo(0, 0);
    }
  }

  fetchContent(pathParam) {
    const readmePath = require(`./content/${pathParam}.md`);
    return fetch(readmePath)
      .then(response => response.text())
      .then(text => {
        this.setState({
          page: pathParam,
          content: marked(text, { breaks: true })
        });
        return text;
      });
  }

  toggleSidebar(value) {
    const newValue = value || !this.state.sideBarOpen;
    this.setState({ sideBarOpen: newValue });
  }

  render() {
    const capitalize = require("capitalize");
    const pathParam = capitalize.words(this.state.page || "Welcome");
    return (
      <DocumentTitle title={`Wego Providers | ${pathParam}`}>
        <Wrapper>
          <Mobile>
            <Sidebar onClick={() => this.toggleSidebar(false)} mobile sideBarOpen={this.state.sideBarOpen}>
              <TableOfContents />
            </Sidebar>
          </Mobile>
          <Desktop>
            <Sidebar>
              <TableOfContents />
            </Sidebar>
          </Desktop>
          <SidebarToggle onClick={() => this.toggleSidebar()} />
          <MainContent>
            <div dangerouslySetInnerHTML={{ __html: this.state.content }} />
          </MainContent>
        </Wrapper>
      </DocumentTitle>
    );
  }
}

export default Providers;

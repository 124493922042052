import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import arrowRightIcon from "../icons/ArrowRightIcon.svg";

const GuideLink = styled(NavLink)`
  color: #4a4a4a;
  float: right;
  display:flex;
  :hover {
    color: #000000;
  }
  > span {
    margin-right: 4px;
  }
`;

export default props => (
  <GuideLink {...props}>
    <span>{props.children}</span><img src={arrowRightIcon} alt="arrow right" />
  </GuideLink>
);

export const cabinOptions = [
  { label: "economy", value: "economy" },
  { label: "premium_economy", value: "premium_economy" },
  { label: "business", value: "business" },
  { label: "first", value: "first" }
];

export const deviceTypes = ["DESKTOP", "TABLET", "MOBILE"];
export const deviceTypeOptions = deviceTypes.map(deviceType => ({
  label: deviceType,
  value: deviceType
}));

export const locales = [
  "en",
  "ar",
  "id",
  "fa",
  "zh-cn",
  "zh-tw",
  "zh-hk",
  "de",
  "es-419",
  "es",
  "fr",
  "it",
  "ja",
  "ko",
  "ms",
  "nl",
  "pl",
  "pt",
  "pt-br",
  "ru",
  "sv",
  "th",
  "tr",
  "vi",
  "ur",
  "te",
  "ta",
  "pa",
  "or",
  "mr",
  "ml",
  "kn",
  "hi",
  "gu",
  "bn"
];

export const localeOptions = locales.map(locale => ({
  label: locale,
  value: locale
}));

export const siteCodes = [
  "DZ",
  "AR",
  "AU",
  "BH",
  "BD",
  "BR",
  "CA",
  "CL",
  "CN",
  "CO",
  "EG",
  "FR",
  "DE",
  "GH",
  "HK",
  "IN",
  "ID",
  "IR",
  "IR",
  "IE",
  "IT",
  "JP",
  "JO",
  "KW",
  "MY",
  "MX",
  "MA",
  "NL",
  "NZ",
  "NG",
  "OM",
  "PK",
  "PH",
  "PL",
  "PT",
  "QA",
  "RU",
  "SA",
  "SG",
  "ZA",
  "KR",
  "ES",
  "LK",
  "SE",
  "CH",
  "TW",
  "TH",
  "TN",
  "TR",
  "AE",
  "GB",
  "US",
  "VN"
];

export const siteCodeOptions = siteCodes.map(sc => ({
  label: sc,
  value: sc
}));

export const routes = {
  home: "/",
  affiliates: {
    home: "/affiliates",
    getStarted: "/affiliates/get-started",
    authentication: "/affiliates/guides/authentication",
    facilitatedBooking: "/affiliates/guides/facilitated-booking",
    guides: "/affiliates/guides",
    reference: "/affiliates/reference",
    terms: "/affiliates/terms"
  },
  providers: "/providers",
  providersHome: "/providers/welcome",
  conversionTracking: "/providers/conversion-tracking",
  hotelsRequirements: "/providers/hotels-integration-requirements",
  flightsRequirements: "/providers/flights-integration-requirements",
  facilitatedBooking: "/providers/facilitated-booking",
  foodDelivery: "/providers/food-delivery-integration-requirements"
};

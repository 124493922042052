import React from "react";
import ImageZoom from "react-medium-image-zoom";
import styled from "styled-components";

const Description = styled.div`
  font-size: 0.72em;
  width: 90%;
  text-align: center;
  font-style: italic;
`;

export default props => (
  <div>
    <ImageZoom
      image={{
        src: props.src,
        alt: props.alt,
        className: "img",
        style: { width: props.width }
      }}
      zoomImage={{
        src: props.src,
        alt: props.alt
      }}
    />
    <Description>{props.description}</Description>
  </div>
);

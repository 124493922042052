import React from "react";
import styled from "styled-components";
import { NavLink as Link } from "react-router-dom";

const NavLink = styled(Link)`
  padding: 0 12px;
  height: 100%;
  display: flex;
  align-items: center;
  color: #ffffff;
  text-decoration: none;
  position: relative;
  background-color: inherit;
  :hover {
    color: #c0c0c0;
    transition: color 0.2s;
  }
  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-top: 3px solid;
    border-color: transparent;
  }
  &.${props => props.activeClassName} {
    background-color: #535353;
    transition: background-color 0.3s ease-in-out;
    ::before {
      border-top: 3px solid #fff;
      transition: border-color 0.3s ease-in-out;
    }
  }
  @media (max-width: 420px) {
    ::before {
      border-top: 1px solid #999999;
    }
  }
`;

const NormalLink = NavLink.withComponent("a");

export default props => {
  if (props.external)
    return <NormalLink {...props} target="_blank" href={props.to} />;
  return <NavLink {...props} activeClassName="selected" />;
};

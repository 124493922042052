import React from "react";

export default props => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    preserveAspectRatio="xMidYMid meet"
  >
    <g>
      <path fillRule="evenodd" d="M15.4 16.6L10.8 12l4.6-4.6L14 6l-6 6 6 6" />
    </g>
  </svg>
);

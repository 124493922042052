export const initialSearchRequest = {
  search: {
    siteCode: "SG",
    locale: "en",
    currencyCode: "SGD",
    cityCode: "LON",
    roomsCount: "1",
    guestsCount: "4",
    checkIn: "2017-12-19",
    checkOut: "2017-12-31",
    deviceType: "DESKTOP",
    appType: "WEB_APP",
    userLoggedIn: false
  },
  offset: 0
};

export const initialResponse = {
  search: {
    id: "fda81f1374870dfe",
    searchType: "CITY",
    city: {
      code: "LON",
      name: "London"
    },
    worldRegion: {
      code: "e",
      name: "Europe"
    },
    country: {
      code: "GB",
      name: "United Kingdom"
    },
    roomsCount: 1,
    guestsCount: 2,
    checkIn: "2017-12-20",
    checkOut: "2018-01-03",
    createdAt: "2017-09-18T16:08:34.306Z",
    locale: "en",
    siteCode: "SG",
    currencyCode: "SGD",
    deviceType: "DESKTOP",
    appType: "WEB_APP",
    userCountryCode: "SG",
    key: "LON~CITY~2017-12-20~2018-01-03~1~2~SG~en~DESKTOP~WEB_APP",
    cityCode: "LON",
    cityName: "London",
    countryCode: "GB",
    countryName: "United Kingdom"
  },
  hotels: [
    {
      id: 155011,
      name: "Comfort Inn Kensington",
      star: 2,
      images: [
        {
          url:
            "http://res.cloudinary.com/wego/image/upload/v1480302749/hotels/155011/12440473.jpg"
        },
        {
          url:
            "http://res.cloudinary.com/wego/image/upload/v1395058553/hotels/427238/12628696.jpg"
        },
        {
          url:
            "http://res.cloudinary.com/wego/image/upload/v1395058553/hotels/427238/12628699.jpg"
        }
      ],
      propertyTypeId: 1,
      brandId: 0,
      chainId: 0,
      districtId: 0,
      imagesCount: 6,
      amenityIds: [],
      longitude: -0.197325,
      latitude: 51.494118
    }
  ],
  propertyTypes: [
    {
      id: 1,
      name: "Hotel"
    }
  ],
  brands: [
    {
      id: 48,
      name: "Best Western"
    }
  ],
  chains: [
    {
      id: 1008,
      name: "GLH Hotels"
    }
  ],
  districts: [
    {
      id: 2049,
      name: "Southwark"
    }
  ],
  amenities: [
    {
      id: 1,
      name: "Highspeed Internet"
    }
  ]
};

export const pollRequest = {
  search: {
    id: "22a293253a8fbf0a"
  },
  offset: 0
};

export const responseWithResults = {
  search: {
    id: "8d6b52983a64ebd9",
    searchType: "CITY",
    city: {
      code: "LON",
      name: "London"
    },
    worldRegion: {
      code: "e",
      name: "Europe"
    },
    country: {
      code: "GB",
      name: "United Kingdom"
    },
    roomsCount: 1,
    guestsCount: 2,
    checkIn: "2017-12-19",
    checkOut: "2018-01-02",
    createdAt: "2017-09-18T05:09:33.733Z",
    locale: "en",
    siteCode: "SG",
    currencyCode: "SGD",
    deviceType: "DESKTOP",
    appType: "WEB_APP",
    userCountryCode: "SG",
    key: "LON~CITY~2017-12-19~2018-01-02~1~2~SG~en~DESKTOP~WEB_APP",
    cityCode: "LON",
    cityName: "London",
    countryCode: "GB",
    countryName: "United Kingdom"
  },
  rates: [
    {
      id: "8d6b52983a64ebd9:hotel.info:154581:178",
      hotelId: 154581,
      providerHotelId: "270932",
      providerCode: "hotel.info",
      createdAt: "2017-09-18T05:09:33.733Z",
      description: "Standard Double Room - Nonflex Hot Deal",
      price: {
        amount: 319,
        currencyCode: "SGD",
        amountUsd: 237.27838,
        taxAmount: 744.13104,
        taxAmountUsd: 553.6496,
        taxInclusive: true,
        ecpc: 0.67,
        localTaxAmount: -1,
        totalAmount: 4466,
        totalAmountUsd: 3321.8975
      },
      rateAmenityIds: [4],
      handoffUrl:
        "http://handoff.wegostaging.com/hotels/continue/154581/405/178?search_id=8d6b52983a64ebd9&site=www.wego.com.sg&locale=en&version=2&device_type=DESKTOP&app_type=WEB_APP"
    }
  ],
  hotels: [
    {
      id: 154581,
      name: "Royal Park Hotel",
      star: 5,
      images: [
        {
          url:
            "http://res.cloudinary.com/wego/image/upload/v1481138017/hotels/154581/14297751.jpg"
        },
        {
          url:
            "http://res.cloudinary.com/wego/image/upload/v1481138017/hotels/154581/14297757.jpg"
        },
        {
          url:
            "http://res.cloudinary.com/wego/image/upload/v1481138014/hotels/154581/2401523.jpg"
        }
      ],
      propertyTypeId: 1,
      brandId: 463,
      chainId: 0,
      districtId: 2043,
      imagesCount: 57,
      amenityIds: [1, 3, 4, 6, 7, 9, 10, 11, 12, 20],
      longitude: -0.1763,
      latitude: 51.5142
    }
  ],
  filter: {
    minPrice: {
      amount: 86,
      currencyCode: "SGD",
      amountUsd: 64.10319,
      taxAmount: 201.03157,
      taxAmountUsd: 149.57184,
      taxInclusive: true,
      ecpc: 0.67,
      localTaxAmount: 0,
      totalAmount: 1204,
      totalAmountUsd: 897.44464
    },
    maxPrice: {
      amount: 752,
      currencyCode: "SGD",
      amountUsd: 559.7779,
      taxAmount: 1755.5186,
      taxAmountUsd: 1306.1438,
      taxInclusive: true,
      ecpc: 0.67,
      localTaxAmount: -1,
      totalAmount: 10528,
      totalAmountUsd: 7836.8906
    },
    rateAmenities: [
      {
        code: "1",
        count: 33
      },
      {
        code: "4",
        count: 36
      },
      {
        code: "5",
        count: 28
      }
    ],
    brands: [
      {
        code: "33",
        count: 1
      },
      {
        code: "103",
        count: 1
      }
    ],
    chains: [],
    propertyTypes: [
      {
        code: "1",
        count: 97
      },
      {
        code: "2",
        count: 1
      }
    ],
    districts: [
      {
        code: "1601",
        count: 2
      },
      {
        code: "2049",
        count: 4
      },
      {
        code: "5475",
        count: 1
      }
    ],
    amenities: [
      {
        code: "1",
        count: 45
      },
      {
        code: "2",
        count: 2
      },
      {
        code: "3",
        count: 16
      }
    ],
    reviewerGroups: [
      {
        code: "COUPLE",
        count: 31
      },
      {
        code: "BUSINESS",
        count: 6
      },
      {
        code: "SOLO",
        count: 24
      },
      {
        code: "ALL",
        count: 34
      },
      {
        code: "FAMILY",
        count: 27
      }
    ],
    stars: [
      {
        code: "2",
        count: 8
      },
      {
        code: "3",
        count: 37
      },
      {
        code: "4",
        count: 51
      },
      {
        code: "5",
        count: 8
      }
    ]
  },
  providerRatesCounts: [
    {
      hotelId: 154581,
      providerCode: "hotel.info",
      ratesCount: 4
    }
  ],
  propertyTypes: [
    {
      id: 1,
      name: "Hotel"
    },
    {
      id: 2,
      name: "Hostel / Backpacker"
    },
    {
      id: 4,
      name: "Serviced Apartment"
    },
    {
      id: 5,
      name: "Resort"
    }
  ],
  stars: [
    {
      id: 1,
      name: "1"
    },
    {
      id: 2,
      name: "2"
    },
    {
      id: 3,
      name: "3"
    },
    {
      id: 4,
      name: "4"
    },
    {
      id: 5,
      name: "5"
    }
  ],
  brands: [
    {
      id: 173,
      name: "Leading Hotels of the World"
    },
    {
      id: 463,
      name: "Park Hotel Group"
    },
    {
      id: 48,
      name: "Best Western"
    }
  ],
  chains: [],
  districts: [
    {
      id: 2042,
      name: "Hammersmith and Fulham"
    },
    {
      id: 2043,
      name: "Westminister"
    },
    {
      id: 2060,
      name: "Tower Hamlets"
    }
  ],
  amenities: [
    {
      id: 1,
      name: "Highspeed Internet"
    },
    {
      id: 3,
      name: "Parking"
    },
    {
      id: 4,
      name: "Restaurant"
    }
  ],
  rateAmenities: [
    {
      id: 1,
      code: "BREAKFAST_INCLUDED",
      name: "Breakfast Included"
    },
    {
      id: 4,
      code: "FREE_WIFI",
      name: "Free WiFi"
    },
    {
      id: 5,
      code: "PAY_LATER",
      name: "Pay Later"
    }
  ],
  providers: [
    {
      code: "hotel.info",
      name: "HOTEL INFO",
      type: "OTA",
      id: 405,
      isMobileFriendly: true,
      isHotelWebsite: false
    }
  ],
  count: 213
};

export const requestWithOffset = {
  search: {
    id: "8d6b52983a64ebd9"
  },
  offset: 100
};

export const ratesResponse = {
  search: {
    id: "8d6b52983a64ebd9"
  }
};

import React, { Component } from "react";
import styled from "styled-components";
import MainContent from "../MainContent";
import Note from "../Note";
import Link from "../Link";
import { routes } from "../../constants";
import DocumentTitle from "react-document-title";

const List = styled.ol`
  > li {
    padding: 8px 0;
  }
`;

const OverviewWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const NextLink = styled(Link)`
  float: right;
`;

class Overview extends Component {
  render() {
    return (
      <DocumentTitle title={`Wego | Affiliates`}>
        <OverviewWrapper>
          <MainContent>
            <h1>Get started</h1>
            <p>
              Welcome developers. Through Wego API and tools, you can build a
              customised travel search experience on your website or mobile
              application with almost all the features and functions of that
              powers Wego. Get creative and use the API in lots of ways.
            </p>
            <h2>Policy</h2>
            <p>
              Please make sure to read the full{" "}
              <a href="https://support.wan.travel/attachments/token/1lqxwnftpoy0l2u/?name=Agtreement_API.pdf">
                API Agreement
              </a>. Some of the rules of using the API:
            </p>
            <ul>
              <li>
                We require to maintain Search to Click ratio not less than 5%
              </li>
              <li>
                You have to use the API to only facilitate travel search queries
                by real-end users (not bots)
              </li>
              <li>
                Only Wego deep links to Wego's partners should be included in
                the search results.
              </li>
            </ul>
            <h2>Rate Limiting</h2>
            <p>
              Use of the Wego API is subject to rate limits. Limits apply to
              Search requests and don't apply to other requests (e.g polling for
              search results, etc).
            </p>
            <p>
              Rate limiting is applied on an application basis (based on client
              id), regardless of how many users are using it.
            </p>
            <p>
              The default limit is 500 calls per hour for a regular key (can be
              increased by request if you maintain Search to Click ratio 5%) and
              50 calls per hour for a test key.
            </p>
            <Note>
              Note that rate limit can be decreased if you don't maintain Search
              to 5% Click ratio.
            </Note>
            <h2>How it works</h2>
            <List>
              <li>
                <strong>Acquire Token</strong>. Your back-end needs to acquire{" "}
                access token. You will need to include the access token for
                every request you send to the API.
              </li>
              <li>
                <strong>Trigger a Search</strong>. Create a search object based
                from the user's input and send this to Wego API. This will
                return a Search ID as a reference for this search session, and
                will be used to get the search results.
              </li>
              <li>
                <strong>Get Results</strong>. Using the Search ID returned in
                Step 2, start polling for the results.
              </li>
              <li>
                <strong>Display results</strong>. You can immediately start
                displaying available results in parallel with polling for more
                results.
              </li>
              <li>
                <strong>Sort, filter and paginate</strong>. Provide great user
                experience by having the ability and power to organise search
                results on the client-side.
              </li>
              <li>
                <strong>Handoff</strong>. Your visitors click on search results
                and are taken to our partner's websites to complete the booking.
              </li>
            </List>
            <h2>Credentials</h2>
            <p>Please contact us to get API Credentials.</p>
            <NextLink to={routes.affiliates.guides}>
              Proceed to Guides ->{" "}
            </NextLink>
          </MainContent>
        </OverviewWrapper>
      </DocumentTitle>
    );
  }
}

export default Overview;

export const initialSearchRequest = {
  paymentMethodIds: [
    10,
    15,
    189,
    191,
    192
  ],
  search: {
    adultsCount: 1,
    appType: "WEB_APP",
    cabin: "economy",
    childrenCount: 0,
    clientCreatedAt: "2024-06-27T14:59:49.185Z",
    currencyCode: "USD",
    deviceType: "DESKTOP",
    infantsCount: 0,
    legs: [
      {
        outboundDate: "2024-07-09",
        departureCityCode: "SIN",
        arrivalCityCode: "LON"
      },
      {
        outboundDate: "2024-07-12",
        departureCityCode: "LON",
        arrivalCityCode: "SIN"
      }
    ],
    locale: "en",
    shopcashClickId: "",
    siteCode: "SG",
    userLoggedIn: false
  }
};

export const initialResponse = {
  legs: [],
  tags: [],
  search: {
    id: "5bf1fab505a74269msr",
    cabin: "economy",
    adultsCount: 1,
    childrenCount: 0,
    infantsCount: 0,
    siteCode: "AE",
    currencyCode: "AED",
    locale: "en",
    deviceType: "desktop",
    appType: "WEB_APP",
    createdAt: "2024-06-27T14:59:49.466Z",
    key: "[cSIN:cLON:2024-07-09-cLON:cSIN:2024-07-12]~1~0~0~AE~economy~desktop~WEB_APP",
    userCountryCode: "VN",
    wgInternalCampaign: "",
    legs: [
      {
        id: "cSIN:cLON:2024-07-09",
        outboundDate: "2024-07-09",
        departureCityCode: "SIN",
        arrivalCityCode: "LON",
        departureCity: {
          code: "SIN",
          name: "Singapore",
          enName: "Singapore",
          countryCode: "SG",
          countryName: "Singapore",
          countryEnName: "Singapore",
          worldRegionCode: "i",
          worldRegionName: "Asia",
          worldRegionEnName: "Asia"
        },
        arrivalCity: {
          code: "LON",
          name: "London",
          enName: "London",
          countryCode: "GB",
          countryName: "United Kingdom",
          countryEnName: "United Kingdom",
          worldRegionCode: "e",
          worldRegionName: "Europe",
          worldRegionEnName: "Europe"
        }
      },
      {
        id: "cLON:cSIN:2024-07-12",
        outboundDate: "2024-07-12",
        departureCityCode: "LON",
        arrivalCityCode: "SIN",
        departureCity: {
          code: "LON",
          name: "London",
          enName: "London",
          countryCode: "GB",
          countryName: "United Kingdom",
          countryEnName: "United Kingdom",
          worldRegionCode: "e",
          worldRegionName: "Europe",
          worldRegionEnName: "Europe"
        },
        arrivalCity: {
          code: "SIN",
          name: "Singapore",
          enName: "Singapore",
          countryCode: "SG",
          countryName: "Singapore",
          countryEnName: "Singapore",
          worldRegionCode: "i",
          worldRegionName: "Asia",
          worldRegionEnName: "Asia"
        }
      }
    ],
    nearbyRoutes: []
  },
  currencies: [
    {
      code: "FJD",
      rate: 2.2382
    },
    {
      code: "MXN",
      rate: 18.377396
    },
    // other currencies omitted for brevity
    {
      code: "BRL",
      rate: 5.515501
    }
  ],
  airlines: [],
  airports: [],
  cities: [],
  providers: [],
  countries: [],
  trips: [],
  fares: [],
  routeSponsors: [],
  scores: {},
  paymentMethods: [],
  fareConditions: [],
  faresCount: {},
  promosCount: {},
  count: 0,
  sponsors: []
};


export const pollRequest = {
  search: {
    id: "5bf1fab505a74269msr"
  },
  offset: 0
};

export const responseWithResults = {
  ...initialResponse,
  count: 2,
  tags: [
    {
      id: 5,
      code: "REFUNDABLE",
      name: "Refundable",
      type: "flexible_tickets",
      price: {
        totalAmount: 3445.0,
        totalAmountUsd: 937.9987,
        currencyCode: "AED"
      }
    }
  ],
  search: {
    id: "5bf1fab505a74269msr",
    cabin: "economy",
    adultsCount: 1,
    childrenCount: 0,
    infantsCount: 0,
    siteCode: "SG",
    currencyCode: "USD",
    locale: "en",
    deviceType: "desktop",
    appType: "WEB_APP",
    createdAt: "2024-06-30T14:18:10.157Z",
    key: "[cSIN:cLON:2024-07-09-cLON:cSIN:2024-07-12]~1~0~0~SG~economy~desktop~WEB_APP",
    userCountryCode: "SG",
    wgInternalCampaign: "",
    legs: [
      {
        id: "cSIN:cLON:2024-07-09",
        outboundDate: "2024-07-09",
        departureCityCode: "SIN",
        arrivalCityCode: "LON",
        departureCity: {
          code: "SIN",
          name: "Singapore",
          enName: "Singapore",
          countryCode: "SG",
          countryName: "Singapore",
          countryEnName: "Singapore",
          worldRegionCode: "i",
          worldRegionName: "Asia",
          worldRegionEnName: "Asia"
        },
        arrivalCity: {
          code: "LON",
          name: "London",
          enName: "London",
          countryCode: "GB",
          countryName: "United Kingdom",
          countryEnName: "United Kingdom",
          worldRegionCode: "e",
          worldRegionName: "Europe",
          worldRegionEnName: "Europe"
        }
      },
      {
        id: "cLON:cSIN:2024-07-12",
        outboundDate: "2024-07-12",
        departureCityCode: "LON",
        arrivalCityCode: "SIN",
        departureCity: {
          code: "LON",
          name: "London",
          enName: "London",
          countryCode: "GB",
          countryName: "United Kingdom",
          countryEnName: "United Kingdom",
          worldRegionCode: "e",
          worldRegionName: "Europe",
          worldRegionEnName: "Europe"
        },
        arrivalCity: {
          code: "SIN",
          name: "Singapore",
          enName: "Singapore",
          countryCode: "SG",
          countryName: "Singapore",
          countryEnName: "Singapore",
          worldRegionCode: "i",
          worldRegionName: "Asia",
          worldRegionEnName: "Asia"
        }
      }
    ],
    nearbyRoutes: []
  },
  airlines: [
    {
      name: "British Airways",
      code: "BA"
    },
    {
      name: "Emirates",
      code: "EK"
    }
  ],
  airports: [
    {
      name: "London Heathrow Airport",
      code: "LHR",
      cityCode: "LON"
    },
    {
      name: "London Stansted Airport",
      code: "STN",
      cityCode: "LON"
    },
    {
      name: "Singapore Changi Airport",
      code: "SIN",
      cityCode: "SIN"
    }
  ],
  cities: [
    {
      code: "LON",
      name: "London",
      countryCode: "GB"
    },
    {
      code: "SIN",
      name: "Singapore",
      countryCode: "SG"
    }
  ],
  providers: [
    {
      code: "kiwi.com",
      name: "Kiwi",
      type: "ota",
      instant: false,
      facilitatedBooking: false,
      wegoFare: false
    },
    {
      code: "onetravel.com",
      name: "Onetravel",
      type: "ota",
      instant: false,
      facilitatedBooking: false,
      wegoFare: false
    }
  ],
  countries: [
    {
      code: "SG",
      name: "Singapore"
    },
    {
      code: "GB",
      name: "United Kingdom"
    }
  ],
  trips: [
    {
      id: "5bf1fab505a74269msr:BA6177~9-BA2364~10=EK68~12-EK352~13",
      originalFlightId: "5bf1fab505a74269msr:BA6177~9-BA2364~10=EK68~12-EK352~13",
      code: "BA6177~9-BA2364~10=EK68~12-EK352~13",
      legIds: [
        "SIN-LHR:BA6177~9:BA2364~10:0",
        "STN-SIN:EK68~12:EK352~13:1"
      ],
      normalizedFlight: false
    },
    {
      id: "5bf1fab505a74269msr:BA2307~9-BA7005~9=BA2365~12-BA2304~13",
      originalFlightId: "5bf1fab505a74269msr:BA2307~9-BA7005~9=BA2365~12-BA2304~13",
      code: "BA2307~9-BA7005~9=BA2365~12-BA2304~13",
      legIds: [
        "SIN-LHR:BA2307~9:BA7005~9:0",
        "LHR-SIN:BA2365~12:BA2304~13:1"
      ],
      normalizedFlight: false
    }
  ],
  legs: [
    {
      id: "SIN-LHR:BA6177~9:BA2364~10:0",
      departureTime: "18:45",
      arrivalTime: "06:25",
      duration: "18h 40m",
      departureAirportCode: "SIN",
      arrivalAirportCode: "LHR",
      airlineCodes: [
        "BA"
      ],
      stopoverAirportCodes: [
        "DOH"
      ],
      allianceCodes: [
        "oneworld"
      ],
      stopoversCount: 1,
      departureTimeMinutes: 1125,
      arrivalTimeMinutes: 385,
      departureDateTime: "2024-07-09T18:45:00.000+08:00",
      arrivalDateTime: "2024-07-10T06:25:00.000+01:00",
      stopoverDurationMinutes: 195,
      durationMinutes: 1120,
      overnight: true,
      stopoverDuration: "03h 15m",
      durationDays: 1,
      longStopover: false,
      segments: [
        {
          durationMinutes: 490,
          stopoverDurationMinutes: 195,
          departureAirportCode: "SIN",
          arrivalAirportCode: "DOH",
          airlineCode: "BA",
          operatingAirlineCode: "QR",
          cabin: "economy",
          designatorCode: "BA6177",
          departureDateTime: "2024-07-09T18:45:00.000+08:00",
          arrivalDateTime: "2024-07-09T21:55:00.000+03:00"
        },
        {
          durationMinutes: 435,
          stopoverDurationMinutes: 0,
          departureAirportCode: "DOH",
          arrivalAirportCode: "LHR",
          airlineCode: "BA",
          cabin: "economy",
          designatorCode: "BA2364",
          departureDateTime: "2024-07-10T01:10:00.000+03:00",
          arrivalDateTime: "2024-07-10T06:25:00.000+01:00"
        }
      ],
      operatingAirlineCodes: [
        "QR"
      ],
      stopoverCode: "ONE_STOP",
      shortStopover: false,
      earlyDeparture: false,
      lateArrival: false,
      newAircraft: false,
      oldAircraft: true,
      highlyRatedCarrier: false,
      score: 841.33
    },
    {
      id: "STN-SIN:EK68~12:EK352~13:1",
      departureTime: "21:10",
      arrivalTime: "21:45",
      duration: "17h 35m",
      departureAirportCode: "STN",
      arrivalAirportCode: "SIN",
      airlineCodes: [
        "EK"
      ],
      stopoverAirportCodes: [
        "DXB"
      ],
      allianceCodes: [
        ""
      ],
      stopoversCount: 1,
      departureTimeMinutes: 1270,
      arrivalTimeMinutes: 1305,
      departureDateTime: "2024-07-12T21:10:00.000+01:00",
      arrivalDateTime: "2024-07-13T21:45:00.000+08:00",
      stopoverDurationMinutes: 175,
      durationMinutes: 1055,
      overnight: true,
      stopoverDuration: "02h 55m",
      durationDays: 1,
      longStopover: false,
      segments: [
        {
          durationMinutes: 420,
          stopoverDurationMinutes: 175,
          departureAirportCode: "STN",
          arrivalAirportCode: "DXB",
          airlineCode: "EK",
          cabin: "economy",
          designatorCode: "EK68",
          departureDateTime: "2024-07-12T21:10:00.000+01:00",
          arrivalDateTime: "2024-07-13T07:10:00.000+04:00"
        },
        {
          durationMinutes: 460,
          stopoverDurationMinutes: 0,
          departureAirportCode: "DXB",
          arrivalAirportCode: "SIN",
          airlineCode: "EK",
          cabin: "economy",
          designatorCode: "EK352",
          departureDateTime: "2024-07-13T10:05:00.000+04:00",
          arrivalDateTime: "2024-07-13T21:45:00.000+08:00"
        }
      ],
      operatingAirlineCodes: [],
      stopoverCode: "ONE_STOP",
      shortStopover: false,
      earlyDeparture: false,
      lateArrival: false,
      newAircraft: false,
      oldAircraft: true,
      highlyRatedCarrier: false,
      score: 845.67
    },
    {
      id: "SIN-LHR:BA2307~9:BA7005~9:0",
      departureTime: "10:30",
      arrivalTime: "22:00",
      duration: "18h 30m",
      departureAirportCode: "SIN",
      arrivalAirportCode: "LHR",
      airlineCodes: [
        "BA"
      ],
      stopoverAirportCodes: [
        "DOH"
      ],
      allianceCodes: [
        "oneworld"
      ],
      stopoversCount: 1,
      departureTimeMinutes: 630,
      arrivalTimeMinutes: 1320,
      departureDateTime: "2024-07-09T10:30:00.000+08:00",
      arrivalDateTime: "2024-07-09T22:00:00.000+01:00",
      stopoverDurationMinutes: 190,
      durationMinutes: 1110,
      overnight: false,
      stopoverDuration: "03h 10m",
      durationDays: 0,
      longStopover: false,
      segments: [
        {
          durationMinutes: 485,
          stopoverDurationMinutes: 190,
          departureAirportCode: "SIN",
          arrivalAirportCode: "DOH",
          airlineCode: "BA",
          operatingAirlineCode: "QR",
          cabin: "economy",
          designatorCode: "BA2307",
          departureDateTime: "2024-07-09T10:30:00.000+08:00",
          arrivalDateTime: "2024-07-09T13:35:00.000+03:00"
        },
        {
          durationMinutes: 435,
          stopoverDurationMinutes: 0,
          departureAirportCode: "DOH",
          arrivalAirportCode: "LHR",
          airlineCode: "BA",
          operatingAirlineCode: "QR",
          cabin: "economy",
          designatorCode: "BA7005",
          departureDateTime: "2024-07-09T16:45:00.000+03:00",
          arrivalDateTime: "2024-07-09T22:00:00.000+01:00"
        }
      ],
      operatingAirlineCodes: [
        "QR"
      ],
      stopoverCode: "ONE_STOP",
      shortStopover: false,
      earlyDeparture: false,
      lateArrival: false,
      newAircraft: false,
      oldAircraft: true,
      highlyRatedCarrier: false,
      score: 842.0
    },
    {
      id: "LHR-SIN:BA2365~12:BA2304~13:1",
      departureTime: "19:25",
      arrivalTime: "20:45",
      duration: "18h 20m",
      departureAirportCode: "LHR",
      arrivalAirportCode: "SIN",
      airlineCodes: [
        "BA"
      ],
      stopoverAirportCodes: [
        "DOH"
      ],
      allianceCodes: [
        "oneworld"
      ],
      stopoversCount: 1,
      departureTimeMinutes: 1165,
      arrivalTimeMinutes: 1245,
      departureDateTime: "2024-07-12T19:25:00.000+01:00",
      arrivalDateTime: "2024-07-13T20:45:00.000+08:00",
      stopoverDurationMinutes: 210,
      durationMinutes: 1100,
      overnight: true,
      stopoverDuration: "03h 30m",
      durationDays: 1,
      longStopover: false,
      segments: [
        {
          durationMinutes: 405,
          stopoverDurationMinutes: 210,
          departureAirportCode: "LHR",
          arrivalAirportCode: "DOH",
          airlineCode: "BA",
          operatingAirlineCode: "QR",
          cabin: "economy",
          designatorCode: "BA2365",
          departureDateTime: "2024-07-12T19:25:00.000+01:00",
          arrivalDateTime: "2024-07-13T04:10:00.000+03:00"
        },
        {
          durationMinutes: 485,
          stopoverDurationMinutes: 0,
          departureAirportCode: "DOH",
          arrivalAirportCode: "SIN",
          airlineCode: "BA",
          operatingAirlineCode: "QR",
          cabin: "economy",
          designatorCode: "BA2304",
          departureDateTime: "2024-07-13T07:40:00.000+03:00",
          arrivalDateTime: "2024-07-13T20:45:00.000+08:00"
        }
      ],
      operatingAirlineCodes: [
        "QR"
      ],
      stopoverCode: "ONE_STOP",
      shortStopover: false,
      earlyDeparture: false,
      lateArrival: false,
      newAircraft: false,
      oldAircraft: true,
      highlyRatedCarrier: false,
      score: 842.67
    }
  ],
  fares: [
    {
      paymentFees: [
        {
          paymentMethodId: 10,
          currencyCode: "AED",
          amount: 0.0,
          amountUsd: 0.0,
          totalAmount: 0.0,
          totalAmountUsd: 0.0
        },
        {
          paymentMethodId: 15,
          currencyCode: "AED",
          amount: 0.0,
          amountUsd: 0.0,
          totalAmount: 0.0,
          totalAmountUsd: 0.0
        }
      ],
      id: "5bf1fab505a74269msr:kiwi.com:e0d59a00316afcc9",
      price: {
        totalAmount: 5131.0,
        totalAmountUsd: 1397.0,
        amount: 5131.0,
        amountUsd: 1397.0,
        originalAmount: 5131.181,
        originalAmountUsd: 1397.0,
        amountPerAdult: 0.0,
        amountPerChild: 0.0,
        amountPerInfant: 0.0,
        taxAmount: 0.0,
        taxAmountUsd: 0.0,
        totalTaxAmount: 0.0,
        totalTaxAmountUsd: 0.0,
        currencyCode: "AED",
        paymentFeeAmountUsd: 0.0,
        bookingFee: 0.0,
        bookingFeeUsd: 0.0,
        totalBookingFee: 0.0,
        totalBookingFeeUsd: 0.0
      },
      providerCode: "kiwi.com",
      handoffUrl: "https://handoff.wego.com/flights/continue?currency=USD&url_locale=en&site_code=SG&device_type=desktop&app_type=WEB_APP&domain=www.wego.com.sg&fare_id=5bf1fab505a74269msr:kiwi.com:e0d59a00316afcc9&route=SIN-LON&search_id=5bf1fab505a74269msr&trip_id=cSIN:cLON:2024-07-09:cLON:cSIN:2024-07-12&pwa=false&api_version=2&integration_code=kiwi.com&region=ap-southeast-1&placement_type=metasearch",
      ecpc: 0.461463,
      remainingSeatsCount: 0,
      conditionIds: [],
      legConditionIds: [],
      refundable: false,
      exchangeable: false,
      tags: [],
      score: 290.0,
      legsSourceTypes: [
        "NORMAL",
        "NORMAL"
      ],
      tripId: "5bf1fab505a74269msr:BA6177~9-BA2364~10=EK68~12-EK352~13"
    },
    {
      paymentFees: [
        {
          paymentMethodId: 3,
          currencyCode: "AED",
          amount: 0.0,
          amountUsd: 0.0,
          totalAmount: 0.0,
          totalAmountUsd: 0.0
        },
        {
          paymentMethodId: 10,
          currencyCode: "AED",
          amount: 0.0,
          amountUsd: 0.0,
          totalAmount: 0.0,
          totalAmountUsd: 0.0
        },
        {
          paymentMethodId: 15,
          currencyCode: "AED",
          amount: 0.0,
          amountUsd: 0.0,
          totalAmount: 0.0,
          totalAmountUsd: 0.0
        }
      ],
      id: "5bf1fab505a74269msr:onetravel.com:54be33645e53dc45",
      price: {
        totalAmount: 3727.0,
        totalAmountUsd: 1014.6,
        amount: 3727.0,
        amountUsd: 1014.6,
        originalAmount: 3726.6257,
        originalAmountUsd: 1014.6,
        amountPerAdult: 3726.6257,
        amountPerChild: 0.0,
        amountPerInfant: 0.0,
        taxAmount: 0.0,
        taxAmountUsd: 0.0,
        totalTaxAmount: 0.0,
        totalTaxAmountUsd: 0.0,
        currencyCode: "AED",
        paymentFeeAmountUsd: 0.0,
        bookingFee: 0.0,
        bookingFeeUsd: 0.0,
        totalBookingFee: 0.0,
        totalBookingFeeUsd: 0.0
      },
      providerCode: "onetravel.com",
      handoffUrl: "https://handoff.wego.com/flights/continue?currency=USD&url_locale=en&site_code=SG&device_type=desktop&app_type=WEB_APP&domain=www.wego.com.sg&fare_id=5bf1fab505a74269msr:onetravel.com:54be33645e53dc45&route=SIN-LON&search_id=5bf1fab505a74269msr&trip_id=cSIN:cLON:2024-07-09:cLON:cSIN:2024-07-12&pwa=false&api_version=2&integration_code=onetravel.com&region=ap-southeast-1&placement_type=metasearch",
      ecpc: 0.442071,
      remainingSeatsCount: 0,
      conditionIds: [],
      legConditionIds: [],
      refundable: false,
      exchangeable: false,
      tags: [],
      score: 670.06995,
      legsSourceTypes: [
        "NORMAL",
        "NORMAL"
      ],
      tripId: "5bf1fab505a74269msr:BA2307~9-BA7005~9=BA2365~12-BA2304~13"
    }
  ],
  filters: {
    minPrice: {
      totalAmount: 3036.0,
      totalAmountUsd: 826.5536,
      amount: 3036.0,
      amountUsd: 826.5536,
      originalAmount: 3035.9314,
      originalAmountUsd: 826.5536,
      amountPerAdult: 3035.9316,
      amountPerChild: 0.0,
      amountPerInfant: 0.0,
      taxAmount: 0.0,
      taxAmountUsd: 0.0,
      totalTaxAmount: 0.0,
      totalTaxAmountUsd: 0.0,
      currencyCode: "AED",
      paymentFeeAmountUsd: 0.0,
      bookingFee: 0.0,
      bookingFeeUsd: 0.0,
      totalBookingFee: 0.0,
      totalBookingFeeUsd: 0.0
    },
    maxPrice: {
      totalAmount: 26116.0,
      totalAmountUsd: 7110.21,
      amount: 26116.0,
      amountUsd: 7110.21,
      originalAmount: 26115.803,
      originalAmountUsd: 7110.21,
      amountPerAdult: 26115.803,
      amountPerChild: 0.0,
      amountPerInfant: 0.0,
      taxAmount: 0.0,
      taxAmountUsd: 0.0,
      totalTaxAmount: 0.0,
      totalTaxAmountUsd: 0.0,
      currencyCode: "AED",
      paymentFeeAmountUsd: 0.0,
      bookingFee: 0.0,
      bookingFeeUsd: 0.0,
      totalBookingFee: 0.0,
      totalBookingFeeUsd: 0.0
    },
    stops: [
      {
        code: "ONE_STOP",
        price: {
          totalAmount: 3077.0,
          totalAmountUsd: 837.7368,
          amount: 3077.0,
          amountUsd: 837.7368,
          originalAmount: 3077.0073,
          originalAmountUsd: 837.7368,
          amountPerAdult: 0.0,
          amountPerChild: 0.0,
          amountPerInfant: 0.0,
          taxAmount: 0.0,
          taxAmountUsd: 0.0,
          totalTaxAmount: 0.0,
          totalTaxAmountUsd: 0.0,
          currencyCode: "AED",
          paymentFeeAmountUsd: 0.0,
          bookingFee: 0.0,
          bookingFeeUsd: 0.0,
          totalBookingFee: 0.0,
          totalBookingFeeUsd: 0.0
        }
      },
      {
        code: "MORE_THAN_ONE_STOP",
        price: {
          totalAmount: 3036.0,
          totalAmountUsd: 826.5536,
          amount: 3036.0,
          amountUsd: 826.5536,
          originalAmount: 3035.9314,
          originalAmountUsd: 826.5536,
          amountPerAdult: 3035.9316,
          amountPerChild: 0.0,
          amountPerInfant: 0.0,
          taxAmount: 0.0,
          taxAmountUsd: 0.0,
          totalTaxAmount: 0.0,
          totalTaxAmountUsd: 0.0,
          currencyCode: "AED",
          paymentFeeAmountUsd: 0.0,
          bookingFee: 0.0,
          bookingFeeUsd: 0.0,
          totalBookingFee: 0.0,
          totalBookingFeeUsd: 0.0
        }
      },
      {
        code: "DIRECT",
        price: {
          totalAmount: 6750.0,
          totalAmountUsd: 1837.79,
          amount: 6750.0,
          amountUsd: 1837.79,
          originalAmount: 6750.203,
          originalAmountUsd: 1837.79,
          amountPerAdult: 6750.203,
          amountPerChild: 0.0,
          amountPerInfant: 0.0,
          taxAmount: 0.0,
          taxAmountUsd: 0.0,
          totalTaxAmount: 0.0,
          totalTaxAmountUsd: 0.0,
          currencyCode: "AED",
          paymentFeeAmountUsd: 0.0,
          bookingFee: 0.0,
          bookingFeeUsd: 0.0,
          totalBookingFee: 0.0,
          totalBookingFeeUsd: 0.0
        }
      }
    ],
    airlines: [
      {
        code: "EK",
        price: {
          totalAmount: 3780.0,
          totalAmountUsd: 1029.22,
          amount: 3780.0,
          amountUsd: 1029.22,
          originalAmount: 3780.325,
          originalAmountUsd: 1029.22,
          amountPerAdult: 3780.325,
          amountPerChild: 0.0,
          amountPerInfant: 0.0,
          taxAmount: 0.0,
          taxAmountUsd: 0.0,
          totalTaxAmount: 0.0,
          totalTaxAmountUsd: 0.0,
          currencyCode: "AED",
          paymentFeeAmountUsd: 0.0,
          bookingFee: 0.0,
          bookingFeeUsd: 0.0,
          totalBookingFee: 0.0,
          totalBookingFeeUsd: 0.0
        }
      },
      {
        code: "BA",
        price: {
          totalAmount: 3391.0,
          totalAmountUsd: 923.24,
          amount: 3391.0,
          amountUsd: 923.24,
          originalAmount: 3391.0605,
          originalAmountUsd: 923.24,
          amountPerAdult: 3391.0605,
          amountPerChild: 0.0,
          amountPerInfant: 0.0,
          taxAmount: 0.0,
          taxAmountUsd: 0.0,
          totalTaxAmount: 0.0,
          totalTaxAmountUsd: 0.0,
          currencyCode: "AED",
          paymentFeeAmountUsd: 0.0,
          bookingFee: 0.0,
          bookingFeeUsd: 0.0,
          totalBookingFee: 0.0,
          totalBookingFeeUsd: 0.0
        }
      }
    ],
    providers: [
      {
        code: "onetravel.com",
        price: {
          totalAmount: 3429.0,
          totalAmountUsd: 933.7,
          amount: 3429.0,
          amountUsd: 933.7,
          originalAmount: 3429.4802,
          originalAmountUsd: 933.7,
          amountPerAdult: 3429.4802,
          amountPerChild: 0.0,
          amountPerInfant: 0.0,
          taxAmount: 0.0,
          taxAmountUsd: 0.0,
          totalTaxAmount: 0.0,
          totalTaxAmountUsd: 0.0,
          currencyCode: "AED",
          paymentFeeAmountUsd: 0.0,
          bookingFee: 0.0,
          bookingFeeUsd: 0.0,
          totalBookingFee: 0.0,
          totalBookingFeeUsd: 0.0
        }
      },
      {
        code: "kiwi.com",
        price: {
          totalAmount: 3813.0,
          totalAmountUsd: 1038.0,
          amount: 3813.0,
          amountUsd: 1038.0,
          originalAmount: 3812.5742,
          originalAmountUsd: 1038.0,
          amountPerAdult: 0.0,
          amountPerChild: 0.0,
          amountPerInfant: 0.0,
          taxAmount: 0.0,
          taxAmountUsd: 0.0,
          totalTaxAmount: 0.0,
          totalTaxAmountUsd: 0.0,
          currencyCode: "AED",
          paymentFeeAmountUsd: 0.0,
          bookingFee: 0.0,
          bookingFeeUsd: 0.0,
          totalBookingFee: 0.0,
          totalBookingFeeUsd: 0.0
        }
      }
    ],
    stopoverAirports: [
      {
        code: "LHR",
        price: {
          totalAmount: 3747.0,
          totalAmountUsd: 1020.0516,
          amount: 3747.0,
          amountUsd: 1020.0516,
          originalAmount: 3746.918,
          originalAmountUsd: 1020.0516,
          amountPerAdult: 3746.918,
          amountPerChild: 0.0,
          amountPerInfant: 0.0,
          taxAmount: 0.0,
          taxAmountUsd: 0.0,
          totalTaxAmount: 0.0,
          totalTaxAmountUsd: 0.0,
          currencyCode: "AED",
          paymentFeeAmountUsd: 0.0,
          bookingFee: 0.0,
          bookingFeeUsd: 0.0,
          totalBookingFee: 0.0,
          totalBookingFeeUsd: 0.0
        }
      },
      {
        code: "DXB",
        price: {
          totalAmount: 3825.0,
          totalAmountUsd: 1041.205,
          amount: 3825.0,
          amountUsd: 1041.205,
          originalAmount: 3825.0872,
          originalAmountUsd: 1041.205,
          amountPerAdult: 3825.0872,
          amountPerChild: 0.0,
          amountPerInfant: 0.0,
          taxAmount: 0.0,
          taxAmountUsd: 0.0,
          totalTaxAmount: 0.0,
          totalTaxAmountUsd: 0.0,
          currencyCode: "AED",
          paymentFeeAmountUsd: 0.0,
          bookingFee: 0.0,
          bookingFeeUsd: 0.0,
          totalBookingFee: 0.0,
          totalBookingFeeUsd: 0.0
        }
      }
    ],
    departureTimeRanges: [
      {
        startTime: "2024-07-15T00:00:00.000Z",
        endTime: "2024-07-15T12:00:00.000Z",
        price: {
          totalAmount: 3780.0,
          totalAmountUsd: 1029.22,
          amount: 3780.0,
          amountUsd: 1029.22,
          originalAmount: 3780.325,
          originalAmountUsd: 1029.22,
          amountPerAdult: 3780.325,
          amountPerChild: 0.0,
          amountPerInfant: 0.0,
          taxAmount: 0.0,
          taxAmountUsd: 0.0,
          totalTaxAmount: 0.0,
          totalTaxAmountUsd: 0.0,
          currencyCode: "AED",
          paymentFeeAmountUsd: 0.0,
          bookingFee: 0.0,
          bookingFeeUsd: 0.0,
          totalBookingFee: 0.0,
          totalBookingFeeUsd: 0.0
        }
      },
      {
        startTime: "2024-07-16T00:00:00.000Z",
        endTime: "2024-07-16T12:00:00.000Z",
        price: {
          totalAmount: 3241.0,
          totalAmountUsd: 881.693,
          amount: 3241.0,
          amountUsd: 881.693,
          originalAmount: 3241.1548,
          originalAmountUsd: 881.693,
          amountPerAdult: 3241.1548,
          amountPerChild: 0.0,
          amountPerInfant: 0.0,
          taxAmount: 0.0,
          taxAmountUsd: 0.0,
          totalTaxAmount: 0.0,
          totalTaxAmountUsd: 0.0,
          currencyCode: "AED",
          paymentFeeAmountUsd: 0.0,
          bookingFee: 0.0,
          bookingFeeUsd: 0.0,
          totalBookingFee: 0.0,
          totalBookingFeeUsd: 0.0
        }
      }
    ],
    arrivalTimeRanges: [
      {
        startTime: "2024-07-15T12:00:00.000Z",
        endTime: "2024-07-15T23:59:59.999Z",
        price: {
          totalAmount: 3391.0,
          totalAmountUsd: 923.24,
          amount: 3391.0,
          amountUsd: 923.24,
          originalAmount: 3391.0605,
          originalAmountUsd: 923.24,
          amountPerAdult: 3391.0605,
          amountPerChild: 0.0,
          amountPerInfant: 0.0,
          taxAmount: 0.0,
          taxAmountUsd: 0.0,
          totalTaxAmount: 0.0,
          totalTaxAmountUsd: 0.0,
          currencyCode: "AED",
          paymentFeeAmountUsd: 0.0,
          bookingFee: 0.0,
          bookingFeeUsd: 0.0,
          totalBookingFee: 0.0,
          totalBookingFeeUsd: 0.0
        }
      },
      {
        startTime: "2024-07-16T12:00:00.000Z",
        endTime: "2024-07-17T00:00:00.000Z",
        price: {
          totalAmount: 6750.0,
          totalAmountUsd: 1837.79,
          amount: 6750.0,
          amountUsd: 1837.79,
          originalAmount: 6750.203,
          originalAmountUsd: 1837.79,
          amountPerAdult: 6750.203,
          amountPerChild: 0.0,
          amountPerInfant: 0.0,
          taxAmount: 0.0,
          taxAmountUsd: 0.0,
          totalTaxAmount: 0.0,
          totalTaxAmountUsd: 0.0,
          currencyCode: "AED",
          paymentFeeAmountUsd: 0.0,
          bookingFee: 0.0,
          bookingFeeUsd: 0.0,
          totalBookingFee: 0.0,
          totalBookingFeeUsd: 0.0
        }
      }
    ],
    durationRanges: [
      {
        startTime: "PT12H0M",
        endTime: "PT24H0M",
        price: {
          totalAmount: 6750.0,
          totalAmountUsd: 1837.79,
          amount: 6750.0,
          amountUsd: 1837.79,
          originalAmount: 6750.203,
          originalAmountUsd: 1837.79,
          amountPerAdult: 6750.203,
          amountPerChild: 0.0,
          amountPerInfant: 0.0,
          taxAmount: 0.0,
          taxAmountUsd: 0.0,
          totalTaxAmount: 0.0,
          totalTaxAmountUsd: 0.0,
          currencyCode: "AED",
          paymentFeeAmountUsd: 0.0,
          bookingFee: 0.0,
          bookingFeeUsd: 0.0,
          totalBookingFee: 0.0,
          totalBookingFeeUsd: 0.0
        }
      },
      {
        startTime: "PT24H0M",
        endTime: "PT36H0M",
        price: {
          totalAmount: 26116.0,
          totalAmountUsd: 7110.21,
          amount: 26116.0,
          amountUsd: 7110.21,
          originalAmount: 26115.803,
          originalAmountUsd: 7110.21,
          amountPerAdult: 26115.803,
          amountPerChild: 0.0,
          amountPerInfant: 0.0,
          taxAmount: 0.0,
          taxAmountUsd: 0.0,
          totalTaxAmount: 0.0,
          totalTaxAmountUsd: 0.0,
          currencyCode: "AED",
          paymentFeeAmountUsd: 0.0,
          bookingFee: 0.0,
          bookingFeeUsd: 0.0,
          totalBookingFee: 0.0,
          totalBookingFeeUsd: 0.0
        }
      }
    ]
  },
  routeSponsors: [],
  scores: {
    "5bf1fab505a74269msr:BA6177~9-BA2364~10=EK68~12-EK352~13": 841.33,
    "5bf1fab505a74269msr:BA2307~9-BA7005~9=BA2365~12-BA2304~13": 842.0
  },
  paymentMethods: [
    {
      id: 3,
      name: "American Express"
    },
    {
      id: 14,
      name: "Visa"
    },
    {
      id: 10,
      name: "MasterCard Credit"
    },
    {
      id: 15,
      name: "Visa Credit"
    },
    {
      id: 187,
      name: "Apple Pay"
    },
    {
      id: 152,
      name: "MADA"
    }
  ],
  fareConditions: [
    {
      id: 1,
      code: "REFUNDABLE",
      name: "Refundable"
    }
  ],
  faresCount: {
    "5bf1fab505a74269msr:BA6177~9-BA2364~10=EK68~12-EK352~13": 1,
    "5bf1fab505a74269msr:BA2307~9-BA7005~9=BA2365~12-BA2304~13": 2
  },
  promosCount: {},
  sponsors: []
};

export const requestWithOffset = {
  search: {
    id: "5bf1fab505a74269msr"
  },
  offset: 100
};

export const tripDetails = {
  paymentMethods: [
    {
      id: 7,
      name: "JCB"
    },
    {
      id: 10,
      name: "MasterCard Credit"
    },
    {
      id: 15,
      name: "Visa Credit"
    }
  ],
  trip: {
    id: "5bf1fab505a74269msr:BA6177~9-BA2364~10=EK68~12-EK352~13",
    originalFlightId: "5bf1fab505a74269msr:BA6177~9-BA2364~10=EK68~12-EK352~13",
    code: "BA6177~9-BA2364~10=EK68~12-EK352~13",
    normalizedFlight: false,
    domestic: false,
    tags: [],
    messages: [],
    fares: [
      {
        id: "5bf1fab505a74269msr:kiwi.com:6887deecd37d2fe4",
        searchId: "5bf1fab505a74269msr",
        price: {
          totalAmount: 1469.0,
          totalAmountUsd: 1469.0,
          amount: 1469.0,
          amountUsd: 1469.0,
          originalAmount: 1469.0,
          originalAmountUsd: 1469.0,
          amountPerAdult: 0.0,
          amountPerChild: 0.0,
          amountPerInfant: 0.0,
          taxAmount: 0.0,
          taxAmountUsd: 0.0,
          totalTaxAmount: 0.0,
          totalTaxAmountUsd: 0.0,
          currencyCode: "USD",
          paymentFeeAmountUsd: 0.0,
          bookingFee: 0.0,
          bookingFeeUsd: 0.0,
          totalBookingFee: 0.0,
          totalBookingFeeUsd: 0.0
        },
        provider: {
          code: "kiwi.com",
          name: "Kiwi",
          type: "ota",
          instant: false,
          facilitatedBooking: false,
          wegoFare: false,
          badges: [],
          starPartner: false,
          colorHexPrimary: "#000000"
        },
        bookingParams: "null",
        paymentFees: [
          {
            paymentMethodId: 7,
            currencyCode: "USD",
            amount: 0.0,
            amountUsd: 0.0,
            totalAmount: 0.0,
            totalAmountUsd: 0.0
          },
          {
            paymentMethodId: 10,
            currencyCode: "USD",
            amount: 0.0,
            amountUsd: 0.0,
            totalAmount: 0.0,
            totalAmountUsd: 0.0
          },
          {
            paymentMethodId: 15,
            currencyCode: "USD",
            amount: 0.0,
            amountUsd: 0.0,
            totalAmount: 0.0,
            totalAmountUsd: 0.0
          }
        ],
        handoffUrl: "https://handoff.wego.com/flights/continue?currency=USD&url_locale=en&site_code=SG&device_type=desktop&app_type=WEB_APP&domain=www.wego.com.sg&fare_id=5bf1fab505a74269msr:kiwi.com:6887deecd37d2fe4&route=SIN-LON&search_id=5bf1fab505a74269msr&trip_id=cSIN:cLON:2024-07-09:cLON:cSIN:2024-07-12&pwa=false&api_version=2&integration_code=kiwi.com&fare_index=1&total_fares=1&region=ap-southeast-1&placement_type=metasearch",
        ecpc: 0.461463,
        remainingSeatsCount: 0,
        conditionIds: [],
        promos: [],
        refundable: false,
        exchangeable: false,
        hasBrandedFares: false,
        tags: [],
        score: 218.0,
        legsSourceTypes: [
          "NORMAL",
          "NORMAL"
        ],
        tripId: "5bf1fab505a74269msr:BA6177~9-BA2364~10=EK68~12-EK352~13"
      }
    ],
    legs: [
      {
        departureTime: "18:45",
        arrivalTime: "06:25",
        duration: "18h 40m",
        departureAirportCode: "SIN",
        arrivalAirportCode: "LHR",
        airlineCodes: [
          "BA",
          "BA"
        ],
        stopoverAirportCodes: [
          "DOH"
        ],
        allianceCodes: [
          "oneworld"
        ],
        stopoversCount: 1,
        departureTimeMinutes: 1125,
        arrivalTimeMinutes: 385,
        departureDateTime: "2024-07-09T18:45:00.000+08:00",
        arrivalDateTime: "2024-07-10T06:25:00.000+01:00",
        stopoverDurationMinutes: 195,
        durationMinutes: 1120,
        overnight: true,
        stopoverDuration: "03h 15m",
        durationDays: 1,
        longStopover: false,
        segments: [
          {
            departureAirportCode: "SIN",
            departureAirportName: "Singapore Changi Airport",
            departureCityName: "Singapore",
            departureCountryCode: "SG",
            departureDate: "9 Jul",
            departureTime: "18:45",
            departureDateTime: "2024-07-09T18:45:00.000+08:00",
            duration: "08h 10m",
            durationMinutes: 490,
            airlineName: "British Airways",
            airlineCode: "BA",
            allianceCode: "oneworld",
            designatorCode: "BA6177",
            arrivalAirportCode: "DOH",
            arrivalAirportName: "Hamad International Airport",
            arrivalCityName: "Doha",
            arrivalCountryCode: "QA",
            arrivalDate: "9 Jul",
            arrivalTime: "21:55",
            arrivalDateTime: "2024-07-09T21:55:00.000+03:00",
            stopoverDuration: "03h 15m",
            stopoverDurationMinutes: 195,
            operatingAirlineName: "Qatar Airways",
            operatingAirlineCode: "QR",
            cabin: "economy"
          },
          {
            departureAirportCode: "DOH",
            departureAirportName: "Hamad International Airport",
            departureCityName: "Doha",
            departureCountryCode: "QA",
            departureDate: "10 Jul",
            departureTime: "01:10",
            departureDateTime: "2024-07-10T01:10:00.000+03:00",
            duration: "07h 15m",
            durationMinutes: 435,
            airlineName: "British Airways",
            airlineCode: "BA",
            allianceCode: "oneworld",
            designatorCode: "BA2364",
            arrivalAirportCode: "LHR",
            arrivalAirportName: "London Heathrow Airport",
            arrivalCityName: "London",
            arrivalCountryCode: "GB",
            arrivalDate: "10 Jul",
            arrivalTime: "06:25",
            arrivalDateTime: "2024-07-10T06:25:00.000+01:00",
            stopoverDurationMinutes: 0,
            aircraftType: "Airbus Industrie A380 800",
            operatingAirlineName: "Qatar Airways",
            operatingAirlineCode: "QR",
            cabin: "economy"
          }
        ],
        shortStopover: false,
        earlyDeparture: false,
        lateArrival: false,
        newAircraft: false,
        oldAircraft: false,
        highlyRatedCarrier: false,
        departureDate: "Tue, 9 Jul",
        arrivalDate: "Wed, 10 Jul"
      },
      {
        departureTime: "21:10",
        arrivalTime: "21:45",
        duration: "17h 35m",
        departureAirportCode: "STN",
        arrivalAirportCode: "SIN",
        airlineCodes: [
          "EK",
          "EK"
        ],
        stopoverAirportCodes: [
          "DXB"
        ],
        allianceCodes: [
          ""
        ],
        stopoversCount: 1,
        departureTimeMinutes: 1270,
        arrivalTimeMinutes: 1305,
        departureDateTime: "2024-07-12T21:10:00.000+01:00",
        arrivalDateTime: "2024-07-13T21:45:00.000+08:00",
        stopoverDurationMinutes: 175,
        durationMinutes: 1055,
        overnight: true,
        stopoverDuration: "02h 55m",
        durationDays: 1,
        segments: [
          {
            departureAirportCode: "STN",
            departureAirportName: "London Stansted Airport",
            departureCityName: "London",
            departureCountryCode: "GB",
            departureDate: "12 Jul",
            departureTime: "21:10",
            departureDateTime: "2024-07-12T21:10:00.000+01:00",
            duration: "07h",
            durationMinutes: 420,
            airlineName: "Emirates",
            airlineCode: "EK",
            allianceCode: "",
            designatorCode: "EK68",
            arrivalAirportCode: "DXB",
            arrivalAirportName: "Dubai International Airport",
            arrivalCityName: "Dubai",
            arrivalCountryCode: "AE",
            arrivalDate: "13 Jul",
            arrivalTime: "07:10",
            arrivalDateTime: "2024-07-13T07:10:00.000+04:00",
            stopoverDuration: "02h 55m",
            stopoverDurationMinutes: 175,
            cabin: "economy"
          },
          {
            departureAirportCode: "DXB",
            departureAirportName: "Dubai International Airport",
            departureCityName: "Dubai",
            departureCountryCode: "AE",
            departureDate: "13 Jul",
            departureTime: "10:05",
            departureDateTime: "2024-07-13T10:05:00.000+04:00",
            duration: "07h 40m",
            durationMinutes: 460,
            airlineName: "Emirates",
            airlineCode: "EK",
            allianceCode: "",
            designatorCode: "EK352",
            arrivalAirportCode: "SIN",
            arrivalAirportName: "Singapore Changi Airport",
            arrivalCityName: "Singapore",
            arrivalCountryCode: "SG",
            arrivalDate: "13 Jul",
            arrivalTime: "21:45",
            arrivalDateTime: "2024-07-13T21:45:00.000+08:00",
            stopoverDurationMinutes: 0,
            cabin: "economy"
          }
        ],
        shortStopover: false,
        earlyDeparture: false,
        lateArrival: false,
        newAircraft: false,
        oldAircraft: false,
        highlyRatedCarrier: false,
        departureDate: "Fri, 12 Jul",
        arrivalDate: "Sat, 13 Jul"
      }
    ]
  },
  fareConditions: [],
  legConditions: []
};

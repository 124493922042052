import React from "react";
import styled from "styled-components";
import Note from "../Note";
import CodeSnippet from "../CodeSnippet";
import Image from "../Image";
import hotelDataReferences from "../../assets/images/hotelDataReferences.png";
import {
  initialSearchRequest,
  initialResponse,
  responseWithResults,
  ratesResponse
} from "./hotelsSampleData";

const Subtext = styled.div`
  font-size: 12px;
  font-style: italic;
`;

const HotelssGuide = () => (
  <div>
    <h1>Hotels</h1>
    <p>
      Through the Wego API, you can search Hotels for a given location and
      dates. The search endpoint will give you all the available hotels upon
      search request. You must then poll to get the best rates for each hotel.
    </p>
    <Note>
      Rate is the object that contains the Price per Provider. For a better
      understanding of the Rate object and it's attributes, see the full
      description in References Section.
    </Note>
    <p>
      By default, the search result only returns one rate per hotel - which is
      the best rate for that hotel. There is another endpoint for getting all
      the available rates for a given hotel.
    </p>
    <Note>
      We highly recommend that you use our{" "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://github.com/wego/wego-sdk-js"
      >
        SDK
      </a>. You can use it to trigger search, fetch results, filter and/or sort
      the results easily. If for some reason you can't use it, please follow
      along our guide below. We are also welcome to start an open source SDK in
      the language of your preference. Please contact us and we will help you.
    </Note>

    <h2>Initiating a Search</h2>
    <p>
      You need to build the search object based from the user's input and
      settings. Here is an example request from a user searching an accomodation
      for their trip to London.
    </p>
    <CodeSnippet label="POST https://affiliate-api.wego.com/metasearch/hotels/searches">
      {`
  Headers:
    Authorization: Bearer eyJ0eXAiOiJKV1QiLCJhbGc...
  Body:
    ${JSON.stringify(initialSearchRequest, null, 4)}`}
    </CodeSnippet>

    <p>
      The endpoint will give you a Search ID. This will be used as a reference
      for getting the hotels and rates on the succeeding calls.
    </p>

    <CodeSnippet label="Response">
      {JSON.stringify(initialResponse, null, 4)}
    </CodeSnippet>

    <p>
      The response also contains 20 hotels. These are the highest ranking hotels
      in London. Wego is returning these so you can show something to the user
      while Wego is working on the background to search for the best prices.
      Showing these hotels immediately have been proven to improve the user
      experience for us. Though, you are free to ignore this and move on to the
      next step to get all the hotels.
    </p>

    <h2>Getting the Search Results</h2>
    <p>
      You need to start polling to get more hotels and its best rates. Let's
      make the first poll.
    </p>
    <p>
      Use the search ID returned in the previous step. Pass offset with value 0.
      We'll discuss what offset is for in the later section of this guide.
    </p>
    <CodeSnippet label="GET https://affiliate-api.wego.com/metasearch/hotels/:searchID">
      {`
  URL: https://affiliate-api.wego.com/metasearch/hotels/searches/:searchID?offset=0&locale=EN&amp;currencyCode=SGD
  Headers:
    Authorization: Bearer eyJ0eXAiOiJKV1QiLCJhbGc...
  URL Params:
    offset: 0
    locale: EN
    currencyCode: SGD`}
    </CodeSnippet>
    <p>
      Although this is the same endpoint, by sending the search ID instead of
      the search information, Wego recognises that this call is to get the
      results of this particular search.
    </p>
    <p>Here's a snippet of the response.</p>
    <CodeSnippet label="Response: HTTP 200">
      {`
    ${JSON.stringify(responseWithResults, null, 4)}`}
    </CodeSnippet>
    <Subtext>
      ^A sample but not a complete response body. Some objects will have more
      attributes.
    </Subtext>
    <Note>
      To check out the full defaults the objects and its attributes included in
      the response body, please refer to Reference page.
    </Note>
    <h2>Understanding the Response</h2>
    <p>
      This response is a subset of the entire search result. It is important to
      understand that you can start showing this subset to the user so they can
      start interacting
    </p>
    <Note>
      <div>Looking up for details</div>
      <p>
        You might notice that most of the objects contain references ( ID or
        Code) to another object from another list. Our API uses this pattern for
        data that can be referenced by multiple objects - to avoid data
        duplication and to trim down size.
      </p>
    </Note>

    <Image width={"100%"} src={hotelDataReferences} alt="data relations" />
    <h2>Displaying the results</h2>
    <p>
      As previously noted, you don't have to keep your users waiting to finish
      all the poll requests before showing the hotels. Use the the available
      hotels and rates now to engage your user. In the background, you can
      continue polling.
    </p>

    <h2>Polling for more results</h2>
    <p>You need to make another request to get the next set of result.</p>
    <p>
      Supposed that the first response returned count with a value 100, you will
      use this as an offset for the succeeding request.
    </p>
    <p>
      You are telling Wego that the next request you only want the 101st rates
      and onwards. Wego will not return the first 100 rates that was already
      returned in the previous poll.
    </p>
    <CodeSnippet label="GET https://affiliate-api.wego.com/metasearch/hotels/:searchID">
      {`
  URL: https://affiliate-api.wego.com/metasearch/hotels/searches/:searchID?offset=0&locale=EN&amp;currencyCode=SGD
  Headers:
    Authorization: Bearer eyJ0eXAiOiJKV1QiLCJhbGc...
  URL Params:
    offset: 100
    locale: EN
    currencyCode: SGD`}
    </CodeSnippet>
    <h2>Merging multiple results</h2>
    <p>
      Using <strong>count</strong> and <strong>offset </strong>means that you
      are getting the results by chunk. This approach is good for performance
      because the size being passed over the network is significantly lower.
    </p>
    <p>
      This means that the responses have to be merged carefully. Collections
      like amenities, brands, chains, districts, propertyTypes, etc need to be
      merged and deduped. ie: a district can appear on multiple responses.
    </p>
    <p>
      You will also need to sort the hotels by rates when merging. The new rates
      might have cheaper prices than the previously returned rates.
    </p>

    <h2>Polling strategy</h2>
    <p>
      We recommend that you keep polling for more results with increasing
      intervals in between.
    </p>
    <span>For example</span>
    <ul>
      <li> Poll #1 - wait 500ms before doing another poll</li>
      <li>Poll #2 - wait 1 second before doing another poll</li>
      <li>Poll #3 - wait 2 seconds before doing another poll</li>
      <li>Poll #4 - wait 3 seconds before doing another poll</li>
      <li>Poll #5 - wait 4 seconds before doing another poll</li>
    </ul>
    <h3>When to stop polling</h3>
    <p>
      If for three consecutive polls returned the same <strong>count</strong>,
      it means that the API has given all the available fares for this
      particular session. On average, it takes about 7 polls to get the entire
      results for a particular search session.
    </p>

    <h2>Filtering</h2>
    <p>
      The filter data is provided by the API. You can see several options under
      <strong>filters</strong> object in the response.
    </p>
    <p>
      We are giving you the responsibility to filter the results. This means
      that when user changed a filter option, you will have to filter the
      results in the client. This main benefit of this approach is performance.
      You don't need to make another network call to filter the search result.
    </p>

    <h2>Sorting</h2>
    <p>
      Sorting is also done client-side. You can implement several sorting
      options. eg: by price, by rating, distance to airport/city center, etc.
    </p>

    <h2>Pagination</h2>
    <p>
      Pagination is also done client-side. You just need to slice the result
      based on your needs.
    </p>

    <h2>Getting more Rates</h2>
    <p>
      The search results only give you a single Rate per hotel. To get all the
      available rates for a hotel. You need to call another endpoint. This
      endpoint will give you rates from different providers in varying prices.
    </p>

    <p>
      This endpoint is particularly useful when user shows interest on a
      particular hotel (by clicking of focusing on it), you can call this
      endpoint and show more rates.
    </p>

    <CodeSnippet label="POST https://affiliate-api.wego.com/metasearch/hotels/:hotelId/searches">
      {JSON.stringify(ratesResponse, null, 4)}}
    </CodeSnippet>
    <br />
  </div>
);

export default HotelssGuide;

import React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import raw from "raw.macro";
import Prism from "prismjs";
import "./prism.css";

const content = raw("./content/FacilitatedBookingGuide.md");

const Container = styled.div`
  max-width: 100%;
  h1,
  h2,
  h3,
  h4,
  strong {
    font-weight: 600;
  }

  blockquote {
    color: #707070;
    font-style: italic;
    padding-left: 8px;
    border-left: 4px solid #707070;
  }

  img {
    max-width: 100%;
  }

  /* pre {
    background-color: #333;
    color: #fff;
    padding: 1rem;
    overflow: scroll;
    width: 100%;
  }

  pre code {
    overflow-y: auto;
    overflow-x: hidden;
  } */
`;

const FacilitatedBookingGuide = () => {
  return (
    <Container>
      <ReactMarkdown source={content} />
    </Container>
  );
};

export default FacilitatedBookingGuide;

import styled from "styled-components";
import { NavLink } from "react-router-dom";

export default styled(NavLink)`
  padding: 4px 8px;
  border-left: ${props => (props.highlightDisabled ? "none" : "4px solid #fff")}
  transition: border-left 0.3s ease-in-out;
  color: #4a4a4a;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
  &.active {
    border-left: ${props =>
      props.highlightDisabled ? "none" : "4px solid #00a400"}
  }
`;

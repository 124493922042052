import React from "react";
import WegoIcon from "../icons/WegoIcon";
import styled from "styled-components";
import { Link } from "react-router-dom";
import OuterLayer from "../OuterLayer";

const HeaderWrapper = styled.header`
  background-color: #4a4a4a;
  position: fixed;
  width: 100vw;
  display: flex;
  justify-content: center;
  > :first-child {
    width: 100%;
    max-width: 1024px;
  }
  z-index: 9999;
  top: 0;
`;

const Header = styled.div`
  position: relative;
  font-family: "Roboto", sans-serif;
  height: 62px;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  padding: 0 32px 0 32px;

  > :first-child {
    flex: 1;
    height: 64px;
    align-items: center;
  }
  > div {
    display: flex;
  }
  @media (max-width: 768px) {
    padding: 0;
  }
  @media (max-width: 420px) {
    padding: 0;
    flex-direction: column;
    > :first-child {
      flex: 1;
      height: 48px;
    }
  }
`;

const Children = styled.div`
  height: 64px;
  @media (max-width: 420px) {
    width: 100%;
    flex-grow: 1;
    height: 40px;
  }
`;

export default props => (
  <OuterLayer>
    <HeaderWrapper>
      <Header>
        <div>
          <Link to="/">
            <WegoIcon />
          </Link>
        </div>
        {props.children && <Children>{props.children}</Children>}
      </Header>
    </HeaderWrapper>
  </OuterLayer>
);

import React from "react";
import styled from "styled-components";
import Note from "../Note";
import CodeSnippet from "../CodeSnippet";
import Image from "../Image";
import dataReferences from "../../assets/images/dataReferences.png";
import GuideLink from "./GuideLink";
import { routes } from "../../constants";

import {
  initialSearchRequest,
  initialResponse,
  responseWithResults,
  tripDetails,
} from "./flightsSampleData";
import Link from "../Link";

const Subtext = styled.div`
  font-size: 12px;
  font-style: italic;
`;

const FlightsGuide = () => (
  <div>
    <h1>Flights Search</h1>
    <p>
      Flights search gives you capability to provide your users live airfares
      for all routes that your users need.
    </p>
    <p>
      By default, the search endpoint will only provide you the best airfare for
      a single Trip. There is a complementary endpoint to get all the available
      fares for a single Trip.
    </p>

    <h2>Initiating a Search</h2>
    <p>
      Based from the user's input and settings - build a search object and use
      it to trigger a search session. Here's an example of a search request from
      a user planning a trip to London.
    </p>
    <CodeSnippet label="POST https://affiliate-api.wego.com/metasearch/flights/searches">
      {`
  Headers:
    Authorization: Bearer eyJ0eXAiOiJKV1QiLCJhbGc...
  Body:
    ${JSON.stringify(initialSearchRequest, null, 4)}`}
    </CodeSnippet>
    <Subtext>
      Having two legs means the request is a roundtrip. Refer to the docs for
      more details on how to construct search request based on your users' needs
      (one way, roundtrip, passenger count, cabin class, etc)
    </Subtext>
    <p>
      The response will contain several objects but in this first response, you
      only need to get the Search ID. This is the indication that Wego has
      started the search and this ID will be the reference for getting the
      results.
    </p>
    <CodeSnippet label="Response">
      {JSON.stringify(initialResponse, null, 4)}
    </CodeSnippet>
    <p>
      Wego is working hard to process the search the moment you send the initial
      request. Now, it's time to get the results.
    </p>

    <h2>Getting the Search Results</h2>
    <p>
      Getting the results require you to poll Wego's API. Wego can not provide
      you all the results in one request. But we can help you gradually show the
      results to the user. You need to start polling to get the results. Let's
      make the first poll.
    </p>
    <p>
      Use the search ID returned in the previous step. Pass offset with value 0.
      We'll discuss what offset is for in the later section of this guide.
    </p>
    <CodeSnippet label="GET https://affiliate-api.wego.com/metasearch/flights/:searchID/results">
      {`
  URL: https://affiliate-api.wego.com/metasearch/flights/searches/:searchID/results?offset=0&locale=EN&amp;currencyCode=SGD
  Headers:
    Authorization: Bearer eyJ0eXAiOiJKV1QiLCJhbGc...
  URL Params:
    offset: 0
    locale: EN
    currencyCode: SGD`}
    </CodeSnippet>

    <p>
      Here's a snippet of the response. You'll notice that objects alongside{" "}
      <strong>Search</strong> have values now.
    </p>

    <CodeSnippet label="Response: HTTP 200">
      {`
    ${JSON.stringify(responseWithResults, null, 4)}`}
    </CodeSnippet>
    <Subtext>
      ^A sample but not a complete response body. Some objects will have more
      attributes.
    </Subtext>
    <Note>
      To check out the full defaults the objects and its attributes included in
      the response body, please refer to Reference page.
    </Note>
    <h2>Understanding the Response</h2>
    <p>
      This response is a subset of the entire search result. It is important to
      understand that you can start showing this subset to the user so they can
      start interacting
    </p>
    <Note>
      <div>Looking up for details</div>
      <p>
        You might notice that most of the objects contain references ( ID or
        Code) to another object from another list. Our API uses this pattern for
        data that can be referenced by multiple objects - to avoid data
        duplication and to trim down size.
      </p>
    </Note>

    <Image
      width={"100%"}
      src={dataReferences}
      alt="data relations"
      description="Trip only has legIds. Full Leg information is in the leg object. Moreover, leg only has airport code. Airport name can be found in the actual airport object."
    />
    <h2>Displaying the results</h2>
    <p>
      You don't have to keep your users waiting to finish the polling before
      showing users the results. Use the the available Trips, and Fares to
      engage your user this early on. While in the background, you can continue
      polling. We will discuss how to poll for more results in the next section.
      Right now, let's not keep your user waiting. You can start displaying the
      available result.
    </p>
    <p>
      The main objects that you will be dealing with in displaying the results
      are Trips and Fares.
    </p>

    <Note>
      <div>
        A <strong>trip</strong> contains references to the legs of a particular
        journey. A trip may have 1, 2 or more than 2 legs depending on the type of the trip.
      </div>
      One leg means the trip is one way. A trip with two legs means it is a
      round trip. And more than 2 legs means it is a multi-city trip.
    </Note>
    <p />
    <br />
    <Note>
      <div>
        <div>
          A <strong>Fare</strong> object holds the price information and the
          provider selling this ticket.
        </div>
        <div>Refer to the docs to know more about the Fare object.</div>
      </div>
    </Note>

    <h2>Polling for more results</h2>
    <p>You need to make another request to get the next set of result.</p>
    <p>
      Supposed that the first response returned count with a value 100, you will
      use this as an offset for the succeeding request.
    </p>
    <p>
      You are telling Wego that the next request you only want the 101st fares
      and onwards. Wego will not return the first 100 fares that was already
      returned in the previous poll.
    </p>
    <CodeSnippet label="GET https://affiliate-api.wego.com/metasearch/flights/:searchID/results">
      {`
  URL: https://affiliate-api.wego.com/metasearch/flights/searches/:searchID/results?offset=0&locale=EN&amp;currencyCode=SGD
  Headers:
    Authorization: Bearer eyJ0eXAiOiJKV1QiLCJhbGc...
  URL Params:
    offset: 100
    locale: EN
    currencyCode: SGD`}
    </CodeSnippet>

    <h2>Merging multiple results</h2>
    <p>
      Using <strong>count</strong> and <strong>offset </strong>means that you
      are getting the results by chunk. This approach is good for performance
      because the size being passed over the network is significantly lower.
    </p>
    <p>
      This means that the responses have to be merged carefully. Collections
      like airports, airlines, countries, legs, filters, etc need to be merged
      and deduped. ie: same airport can appear on multiple responses.
    </p>
    <p>
      You will also need to sort the fares when merging. The new fares might be
      have cheaper prices than the previously returned fares.
    </p>

    <h2>Polling strategy</h2>
    <p>
      We recommend that you keep polling for more results with increasing
      intervals in between.
    </p>
    <span>For example</span>
    <ul>
      <li> Poll #1 - wait 500ms before doing another poll</li>
      <li>Poll #2 - wait 1 second before doing another poll</li>
      <li>Poll #3 - wait 2 seconds before doing another poll</li>
      <li>Poll #4 - wait 3 seconds before doing another poll</li>
      <li>Poll #5 - wait 4 seconds before doing another poll</li>
    </ul>
    <h3>When to stop polling</h3>
    <p>
      If for three consecutive polls returned the same <strong>count</strong>,
      it means that the API has given all the available fares for this
      particular session. On average, it takes about 7 polls to get the entire
      results for a particular search session.
    </p>

    <h2>Filtering</h2>
    <p>
      The filter data is provided by the API. You can see several options under&nbsp;
      <strong>filters</strong> object in the response.
    </p>
    <p>
      We are giving you the responsibility to filter the results. This means
      that when user changed a filter option, you will have to filter the
      results in the client. This main benefit of this approach is performance.
      You don't need to make another network call to filter the search result.
    </p>

    <h2>Sorting</h2>
    <p>
      Sorting is also done client-side. You can implement several sorting
      options. eg: by price, by flight duration, departure time, etc.
    </p>

    <h2>Pagination</h2>
    <p>
      Pagination is also done client-side. You just need to slice the result
      based on your needs.
    </p>

    <h2>Getting more Fares</h2>
    <p>
      So far, you have been calling one endpoint to trigger search and poll for
      results. This endpoint only gives you the best fare for a given trip. We
      have another endpoint to get all the available fares - these fares are
      from different OTAs and airlines in varying prices.
    </p>

    <p>
      When a user shows interest in a particular trip (by clicking or focusing
      on it), you can show more fares and additional information regarding that
      trip. Let's send below request:
    </p>

    <CodeSnippet label="GET https://affiliate-api.wego.com/metasearch/flights/trips/57b9519e27b02524:CX714-CX253=CX354-CX691" />

    <CodeSnippet label="Trip details and Fares">
      {JSON.stringify(tripDetails, null, 4)}}
    </CodeSnippet>
    <br />
    
    
    <h2>Sample Postman Collection</h2>
    <p>
      Access a sample Postman collection to explore and test all our API requests.{" "}
      <a href="/Affiliates API.postman_collection.json" download>
        Download it here.
      </a>
    </p>
    
    <br />
    <GuideLink to={`${routes.affiliates.guides}/hotels`}>
      How to Search for Hotels
    </GuideLink>
  </div>
);

export default FlightsGuide;

import React from "react";
import styled from "styled-components";
import Sticky from "react-stickynode";
import Drawer from "react-motion-drawer";

const Wrapper = styled.div`
  width: 30%;
  margin-top: ${props => (props.mobile ? "104px" : "32px")};

  padding: 0 8px;
  width: 232px;
  h2,
  h3,
  h4 {
    margin-top: 0;
    padding-top: 0;
  }
  a {
    color: #4a4a4a;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;
const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  a {
    margin: 2px 0;
  }
`;

const drawerProps = {
  overlayColor: "rgba(255,255,255,0.6)",
  drawerStyle: {
    background: "#F9F9F9",
    boxShadow:
      "rgba(0, 0, 0, 0.188235) 0px 10px 20px, rgba(0, 0, 0, 0.227451) 0px 6px 6px"
  }
};

export default props => (
  <React.Fragment>
    {props.mobile ? (
      <Drawer open={props.sideBarOpen} width={240} {...drawerProps}>
        <Wrapper {...props}>
          <Sidebar>{props.children}</Sidebar>
        </Wrapper>
      </Drawer>
    ) : (
      <Wrapper {...props}>
        <Sticky top={72 + 63}>
          <Sidebar>{props.children}</Sidebar>
        </Sticky>
      </Wrapper>
    )}
  </React.Fragment>
);

import React from "react";
import styled from "styled-components";
import AccessTokenFlow from "../icons/AccessTokenFlow.svg";
import CodeSnippet from "../CodeSnippet";
import Note from "../Note";
import GuideLink from "./GuideLink";
import { routes } from "../../constants";

const authRequest = {
  client_id: "<client id>",
  grant_type: "client_credentials",
  scope: "affiliate",
};

const authResponse = {
  access_token: "eyJ0eXAiOiJKV1QiLCJhbGc...",
  token_type: "bearer",
  expires_in: 43199,
  scope: "affiliates",
  created_at: 1500000000,
};

const ImageWrapper = styled.div`
  padding: 16px 0;
`;

const AuthenticationGuide = () => (
  <div>
    <h1>Authentication</h1>
    <div>
      <ImageWrapper>
        <img src={AccessTokenFlow} alt="access token flow" />
      </ImageWrapper>
      <p>
        You will need to acquire access token to be able to use Wego's API. To
        request for a token, call the authentication endpoint with your client
        ID.
      </p>
      <CodeSnippet label="POST https://affiliate-api.wego.com/apps/oauth/token">
        {JSON.stringify(authRequest, null, 4)}
      </CodeSnippet>
      <p>
        This will return a response with an <strong>access_token</strong> along
        with useful information.
      </p>
      <CodeSnippet label="Response">
        {JSON.stringify(authResponse, null, 4)}
      </CodeSnippet>
      <Note>
        Your credentials should be kept private. Thus, you will have to acquire
        the access_token from your backend, and pass it onto the client.
      </Note>
      <p>
        Now, that you have <strong>access_token</strong>, we can start
        facilitating flights or hotels search for your users.
      </p>
      <GuideLink to={`${routes.affiliates.guides}/flights`}>
        How to Search for Flights
      </GuideLink>
    </div>
  </div>
);

export default AuthenticationGuide;
